import { createI18n } from 'vue-i18n'
import da from './lan/da'
import de from './lan/de'
import en from './lan/en-US'
import es from './lan/es'
import fi from './lan/fi'
import frCA from './lan/fr-CA'
import frFR from './lan/fr-FR'
import it from './lan/it'
import nl from './lan/nl'
import no from './lan/no'
import ptBR from './lan/pt-BR'
import ptPT from './lan/pt-PT'
import sv from './lan/sv'

export const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'en-US',
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  fallbackLocale: 'en-US',
  messages: {
    ...en,
    ...es,
    ...de,
    ...frCA,
    ...frFR,
    ...it,
    ...nl,
    ...no,
    ...ptBR,
    ...ptPT,
    ...fi,
    ...sv,
    ...da,
  },
})
