export interface IMedia {
  id?: string
  videoCodecName?: string
  height?: number
  width?: number
  duration?: number
  size?: number
  format?: string
  audioCodecName?: string
  audioChannels?: number
  frameRate?: number
  aspectRatio?: number | string
  type?: string
  url?: string
  requestId?: string
  displayAspectRatio?: string
  error?: ErrorMessage
  facebook?: FacebookErrorResponse
  instagram?: InstagramErrorResponse
  google?: ErrorResponse
  linkedin?: ErrorResponse
  twitter?: ErrorResponse
  tiktok?: ErrorResponse
  tiktokBusiness?: ErrorResponse
  youtube?: {
    video: ErrorResponse
    short: ErrorResponse
    verified: ErrorResponse
  }
  displayDuration?: string
  invalid?: boolean
  editing?: boolean
  watermarkUrl?: string
  originalUrl?: string
  watermarkId?: string
  name?: string
  audioSampleRate?: number
  audioBitrate?: number
  videoBitrate?: number
  pinterest?: ErrorResponse
  isUnsupportedFormat?: boolean
  errorMessages?: ErrorMessage[]
  pages?: number
  instagramMedia?: any
  primaryMedia?: any
  isInstaMedia?: boolean
}

export interface IVideo extends IMedia {
  frames: string[]
  selectedPoster: number | 0
  thumbnail: string | undefined
}

export enum MediaErrors {
  IMAGE_SIZE = 'imageSize',
  IMAGE_TYPE = 'imageType',
  IMAGE_WIDTH = 'imageWidth',
  IMAGE_ASPECT_RATIO = 'imageAspectRatio',
  VIDEO_TYPE = 'videoType',
  VIDEO_DURATION = 'videoDuration',
  VIDEO_SIZE = 'videoSize',
  VIDEO_ASPECT_RATIO = 'videoAspectRatio',
  VIDEO_WIDTH_HEIGHT = 'videoWidthHeight',
  VIDEO_AUDIO_CODEC = 'audioCodec',
  VIDEO_AUDIO_CHANNEL = 'audioChannel',
  VIDEO_CODEC = 'videoCodec',
  VIDEO_FRAME_RATE = 'videoFrameRate',
  IMAGE_DIMENSION = 'imageDimension',
  VIDEO_BITRATE = 'videoBitrate',
  AUDIO_BITRATE = 'audioBitrate',
}

export interface ErrorResponse {
  invalid: boolean
  error: ErrorMessage
}

export interface ErrorMessage {
  invalid?: boolean
  title: string
  message: string
  invalidType: MediaErrors | DocumentErrors
}

export interface InstagramErrorResponse {
  post?: ErrorResponse
  story?: ErrorResponse
  reel?: ErrorResponse
}

export interface FacebookErrorResponse {
  post?: ErrorResponse
  reel?: ErrorResponse
}

export interface MediaEditResponse {
  newUrl: string | undefined
  oldUrl: string
}

export enum DocumentErrors {
  DOC_TYPE = 'docType',
  DOC_SIZE = 'docSize',
  DOC_ACCESS = 'docAccess',
}
