<script setup lang="ts">
import {
  UIDivider,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
} from '@gohighlevel/ghl-ui'
import { computed } from 'vue'
import { useSocialMediaStore } from '../../store'

const props = defineProps({
  showModal: {
    type: Boolean,
    default: true,
  },
})
const store = useSocialMediaStore()
const emits = defineEmits(['close', 'refresh'])
const showModal = computed(() => props.showModal)

function closeModal() {
  emits('refresh')
  emits('close')
}

function connectAccount() {
  store.connectAccount('instagram')
  emits('close')
}
</script>
<template>
  <UIModal :show="showModal" id="modal">
    <template #header>
      <UIModalHeader
        id="modal-header"
        type="success"
        :title="$t('instagramModal.title')"
        :description="$t('instagramModal.description')"
        @close="closeModal"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIDivider />
      <ul role="list" class="px-4">
        <li>
          <div class="relative pb-8">
            <span
              class="absolute top-4 ml-3 h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            ></span>
            <div class="relative flex space-x-3">
              <div>
                <span
                  class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white mt-3"
                >
                  <!-- Heroicon name: solid/user -->
                  <img
                    src="https://static.leadconnectorhq.com/pmd/img/logo_medallions/fb.svg"
                    class="w-10 h-10"
                  />
                </span>
              </div>
              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <div class="text-black font-medium text-base py-2">
                    {{ $t('instagramModal.step1') }}
                  </div>
                  <p class="text-sm text-gray-500 ml-3">
                    {{ $t('instagramModal.step1Description') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="relative">
            <div class="relative flex space-x-3">
              <div>
                <span
                  class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white mt-3"
                >
                  <!-- Heroicon name: solid/thumb-up -->
                  <img
                    src="https://static.leadconnectorhq.com/pmd/img/logo_medallions/insta.svg"
                    class="w-10 h-10"
                  />
                </span>
              </div>
              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div class="text-black font-medium text-base py-2">
                  {{ $t('instagramModal.step2') }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </UIModalContent>
    <template #footer>
      <UIModalFooter
        :positiveText="$t('instagramModal.login')"
        :negativeText="$t('common.cancel')"
        @positive-click="connectAccount"
        @negative-click="closeModal"
        id="modal-footer"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>
