import { IMedia } from '@/types/media'
import { ImageBase } from './base'

export class TikTok extends ImageBase {
  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
  }

  protected aspectRatioError(): void {
    // Do nothing
  }
}
