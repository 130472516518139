import { MediaValidator } from '../components/post/validator/mediaValidator'
import { IMedia } from '../types/media'
import { aspectRatioString, aspectRatioValue } from './useAspectRatio'
export function fileInfoFromUrl(url: string) {
  try {
    const http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    http.send(null)
    if (http.status === 200) {
      let contentLength = http.getResponseHeader('content-length')
      if (!contentLength) contentLength = '20240'

      return {
        size: parseInt(contentLength),
        mimeType: http.getResponseHeader('Content-Type'),
      }
    }
    return {
      size: 0,
      mimeType: '',
    }
  } catch (error) {
    console.error(error)
    return {
      size: 0,
      mimeType: '',
    }
  }
}

export const useImageHelper = async (
  media: any,
  isThumbnail: boolean,
  requestId?: string
): Promise<IMedia> => {
  return new Promise(resolve => {
    try {
      const imageName = getImageName(media)
      const image: IMedia = {
        url: media.url,
        type: media.type || 'image/jpeg',
        size: 0,
        width: 0,
        height: 0,
        invalid: false,
        requestId,
        editing: false,
        name: imageName,
        watermarkUrl: media.watermarkUrl,
        originalUrl: media.originalUrl,
        watermarkId: media.watermarkId,
      }
      if (media.type && media.type.includes('tiff')) {
        resolve({
          ...image,
          isUnsupportedFormat: true,
          invalid: true,
          name: '',
          type: '',
        })
      }

      const img = new Image()
      img.src = media.url
      img.onload = () => {
        const fileInfo = media.imageMetaData || fileInfoFromUrl(media.url)
        const w = img.width
        const h = img.height
        image.width = w
        image.height = h
        image.size = fileInfo.size ? parseInt(fileInfo.size) : 0
        image.type = fileInfo.mimeType || media.type || ''
        image.displayAspectRatio = aspectRatioString(w, h, 'image')
        image.aspectRatio =
          aspectRatioValue(image.width, image.height) || undefined
        image.invalid = false

        resolve({
          ...new MediaValidator(image, isThumbnail).getError(),
        } as IMedia)
      }
      img.onerror = err => {
        console.error('Failed to load image:', err)
        resolve({
          url: media.url,
          type: '',
          size: 0,
          width: 0,
          height: 0,
          invalid: true,
          name: '',
        })
      }
    } catch (err) {
      console.error(err)
      resolve({
        url: media.url,
        type: '',
        size: 0,
        width: 0,
        height: 0,
        invalid: true,
        name: '',
      })
    }
  })
}

export const useCsvImageHelper = async (media: any): Promise<IMedia> => {
  return new Promise(resolve => {
    try {
      const image: IMedia = {
        url: media.url,
        type: media.type || 'image/jpeg',
        size: media.size,
        width: media.width,
        height: media.height,
        invalid: false,
        displayAspectRatio: aspectRatioString(
          media.width,
          media.height,
          'image'
        ),
        aspectRatio:
          media.aspectRatio || aspectRatioValue(media.width, media.height),
      }
      resolve({
        ...new MediaValidator(image, false).getError(),
      })
    } catch (err) {
      console.error(err)
      resolve({
        url: media.url,
        type: '',
        size: 0,
        width: 0,
        height: 0,
        invalid: true,
        name: '',
      } as IMedia)
    }
  })
}

function getImageName(media: any) {
  try {
    let name = ''
    if (media.name) {
      name = media.name
    } else if (media.url.includes('unsplash')) {
      const url = media.url.split('/').pop()
      name = `${url.split('?')[0]}.jpg`
    } else {
      name = media.url.split('/').pop()
    }
    return name
  } catch (error) {
    return ''
  }
}
