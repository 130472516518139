<script setup lang="ts">
import {
  AlertTriangleIcon,
  CheckIcon,
  SearchLgIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIDivider,
  UIInput,
  UIModal,
  UIModalContent,
  UIModalHeader,
  UISpace,
  UISpin,
  UITextSmMedium,
  UITextSmNormal,
  UITextXsRegular,
} from '@gohighlevel/ghl-ui'
import { PropType, computed, h, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { OAuthService } from '../../../service'
import { useSocialMediaStore } from '../../../store'

export interface Location {
  account?: Account
  location: LocationData
  error?: boolean
  helpMessage?: string
  helpUrl?: string
}

export interface Account {
  name: string
  accountName: string
  type: string
  verificationState: string
  vettedState: string
}

export interface LocationData {
  name?: string
  storeCode?: string
  title: string
  maxLocation?: boolean
  isVerified?: string | boolean
  isConnected?: boolean
  storefrontAddress?: {
    regionCode?: string
    languageCode?: string
    postalCode?: string
    administrativeArea?: string
    locality?: string
    addressLines?: string[]
  }
}

const props = defineProps({
  showModal: {
    type: Boolean,
    default: true,
  },
  oauthId: {
    type: String,
    default: '',
  },
  reconnectFilter: {
    type: Boolean,
    default: false,
  },
  reconnectAccounts: {
    type: Array as PropType<any[]>,
    default() {
      return []
    },
  },
})
const { t } = useI18n()
const store = useSocialMediaStore()
const emits = defineEmits(['close', 'refresh'])
const showModal = computed(() => props.showModal)
const locations = ref<Location[]>([])
const searchWord = ref('')
const loader = ref(true)

const searchLocations = computed(() => {
  if (!searchWord.value) {
    return locations.value
  } else {
    const inputValue = searchWord.value.toUpperCase()
    return locations.value.filter(
      (x: Location) => x.location.title.toUpperCase().indexOf(inputValue) > -1
    )
  }
})

const placeholder = computed(() => {
  const str = t('connectModal.gmb.searchPlaceholder')
  return str.length > 48 ? `${str.substring(0, 48)}...` : str
})

watch(showModal, async () => {
  if (showModal.value) {
    searchWord.value = ''
    await fetchAccounts()
  }
})

function closeModal() {
  emits('refresh')
  emits('close')
}

async function fetchAccounts() {
  try {
    loader.value = true

    if (props.reconnectFilter) {
      filterReconnectAccounts()
    } else if (props.reconnectAccounts.length) {
      reconnectedAccounts()
    } else {
      locations.value = []
      const { data } = await OAuthService.fetchGmbLocations(
        store.locationId,
        props.oauthId
      )
      locations.value = data.locations
    }

    loader.value = false
  } catch (error) {
    loader.value = false
    console.log(error)
  }
}

async function addAccount(account: Location) {
  try {
    loader.value = true
    if (props.reconnectFilter) {
      store.connectAccount('google', true)
      loader.value = false
      closeModal()
    } else {
      const body = account
      await OAuthService.updateGmbLocation(
        store.locationId,
        props.oauthId,
        body
      )
      locations.value = []
      const { data } = await OAuthService.fetchGmbLocations(
        store.locationId,
        props.oauthId
      )
      locations.value = data.locations
      loader.value = false
    }
  } catch (error) {
    loader.value = false
    console.log(error)
  }
}

function concatAddress(location: LocationData) {
  try {
    let address = ''
    if (location.storefrontAddress) {
      const loc = location.storefrontAddress as any
      if (loc.addressLines?.length) address = loc.addressLines.join(', ')
      if (loc.locality) address += `, ${loc.locality}`
      if (loc.administrativeArea) address += `, ${loc.administrativeArea}`
      if (loc.postalCode) address += `, ${loc.postalCode}`

      address = address.replace(/^,|,$/g, '')
    }
    return address
  } catch (error) {
    console.log(error)
  }
}

async function filterReconnectAccounts() {
  try {
    locations.value = []
    locations.value = store.accounts
      .filter(account => account.platform == 'google' && account.isExpired)
      .map(ele => {
        return {
          location: {
            name: ele.meta?.locationId,
            storeCode: ele.meta?.storeCode,
            title: ele.name,
            isVerified: ele.meta?.isVerified,
            storefrontAddress: ele.meta?.storefrontAddress,
          },
        }
      })
  } catch (error) {
    console.log(error)
  }
}

async function reconnectedAccounts() {
  try {
    locations.value = []
    store.accounts.forEach(account => {
      const reconnectData = props.reconnectAccounts.find(
        ele => ele.id === account.id
      )
      if (account.platform == 'google' && reconnectData) {
        locations.value.push({
          location: {
            name: account.meta?.locationId,
            storeCode: account.meta?.storeCode,
            title: account.name,
            isVerified: account.meta?.isVerified,
            storefrontAddress: account.meta?.storefrontAddress,
            isConnected: reconnectData.isConnected,
          },
          error: reconnectData.error,
          helpMessage: reconnectData.helpMessage,
          helpUrl: reconnectData.helpUrl,
        })
      }
    })
    return { locations: locations.value }
  } catch (error) {
    console.log(error)
  }
}

function redirectUrl(url: string | undefined) {
  try {
    store.redirectUrl(url)
  } catch (error) {
    console.log(error)
  }
}

function icon() {
  return h('img', {
    src: 'https://storage.googleapis.com/msgsndr/DfNXu4rjkbMpKZcEmD8F/media/656430199762af4c94c9cf64.svg+xml',
    class: 'w-8 h-8',
  })
}
</script>
<template>
  <UIModal :show="showModal" id="modal" :width="560">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :title="$t('connectModal.gmb.title')"
        description=""
        :icon="icon"
        @close="closeModal"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIInput
        id="search-box"
        type="text"
        v-model:model-value="searchWord"
        :placeholder="placeholder"
      >
        <template #prefix>
          <SearchLgIcon class="w-4 h-5 text-gray-400" />
        </template>
      </UIInput>
      <UIDivider />
      <div
        v-if="loader"
        class="flex flex-col space-y-4 justify-center items-center"
      >
        <UISpin size="large"></UISpin>
      </div>
      <div v-else class="max-h-[450px] overflow-y-scroll pr-3">
        <div
          v-for="location of searchLocations"
          :key="location.location.name"
          :vertical="true"
          size="large"
          class="pt-4"
        >
          <UISpace
            class="items-center"
            :class="{
              'opacity-50': location.error || location.location.maxLocation,
            }"
          >
            <div class="w-[350px]">
              <UISpace size="large" class="items-center">
                <UISpace :vertical="true" size="small">
                  <UISpace size="small">
                    <UITextSmMedium class="font-medium">{{
                      location.location.title
                    }}</UITextSmMedium>
                    <span
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                      :class="[
                        {
                          'bg-green-100 text-green-600':
                            location.location.isVerified,
                        },
                        {
                          'bg-red-100 text-red-800':
                            !location.location.isVerified,
                        },
                      ]"
                    >
                      {{
                        location.location.isVerified
                          ? $t('common.verified')
                          : $t('common.unverified')
                      }}
                    </span>
                  </UISpace>

                  <UITextXsRegular class="text-gray-400 text-xs">{{
                    concatAddress(location.location)
                  }}</UITextXsRegular>
                </UISpace>
              </UISpace>
            </div>
            <div class="w-[120px] flex justify-end">
              <UISpace
                v-if="location.location.isConnected"
                class="items-center text-success-600"
              >
                <CheckIcon class="w-4 h-4" />
                <UITextSmNormal>{{ $t('common.connected') }}</UITextSmNormal>
              </UISpace>
              <UIButton
                id="add-page"
                v-else-if="
                  !props.reconnectAccounts.length &&
                  !location.location.maxLocation
                "
                type="primary"
                @click.prevent="addAccount(location)"
                >{{
                  props.reconnectFilter
                    ? $t('common.reconnect')
                    : $t('common.add')
                }}</UIButton
              >
            </div>
          </UISpace>
          <UISpace size="large" class="items-center pt-1" v-if="location.error">
            <AlertTriangleIcon class="h-8 w-8 text-red-400" />
            <p v-if="location.helpUrl" class="text-xs text-gray-400 w-[420px]">
              {{ location.helpMessage }}<br /><span
                class="font-bold text-normal text-blue-600 cursor-pointer"
                @click.prevent="redirectUrl(location.helpUrl)"
                >{{ $t('connectModal.gmb.locationPermissionError') }}</span
              >
            </p>
            <p v-else class="text-xs text-gray-400 w-[420px]">
              {{ location.helpMessage }}
            </p>
          </UISpace>
          <UISpace
            size="large"
            class="items-center pt-1"
            v-if="location.location.maxLocation"
          >
            <AlertTriangleIcon class="h-8 w-8 text-red-400" />
            <p class="text-xs text-gray-400 w-[420px]">
              {{ $t('connectModal.gmb.chainedLocationError') }}
            </p>
          </UISpace>
        </div>
        <div
          v-if="!searchLocations.length && !searchWord"
          class="w-full my-2 text-red-500 text-sm"
        >
          {{ $t('connectModal.gmb.noLocationError') }}
        </div>
        <div
          v-else-if="!searchLocations.length"
          class="w-full my-2 text-red-500 text-sm"
        >
          {{ $t('connectModal.gmb.noSearchedLocationError') }}
        </div>
        <div
          v-if="searchLocations.length"
          class="mt-16 flex flex-row justify-start items-start text-sm w-11/12 text-black"
          v-html="$t('connectModal.gmb.allowTitle')"
        ></div>
        <div
          v-if="searchLocations.length"
          class="w-11/12 items-start justify-start flex flex-row px-3"
        >
          <ul class="text-gray-400 list-disc px-2 my-2">
            <li class="text-dodger-blue-500 text-sm">
              <span class="text-gray-400 text-xs">{{
                $t('connectModal.gmb.allowDescription1')
              }}</span>
            </li>
          </ul>
        </div>
        <div
          v-if="searchLocations.length"
          class="w-11/12 flex flex-row justify-start items-start text-sm font-medium text-black mt-2 mb-1"
        >
          {{ $t('connectModal.gmb.allowTitle2') }}
        </div>
        <div
          v-if="searchLocations.length"
          class="w-11/12 my-1 text-xs text-gray-400 flex flex-row"
        >
          {{ $t('connectModal.gmb.allowDescription2') }}
        </div>
      </div>
    </UIModalContent>
  </UIModal>
</template>
