import AddSocialAccountModal from '../module/sv.add_social_account_modal.locale.json'
import CommonLocale from '../module/sv.common.locale.json'
import ConnectModal from '../module/sv.connect_modal.locale.json'
import CreatePost from '../module/sv.create_post.locale.json'
import CreateReviewPost from '../module/sv.create_review_post.locale.json'
import CreateRssPost from '../module/sv.create_rss_post.locale.json'
import CsvPost from '../module/sv.csv_post.locale.json'
import CustomValues from '../module/sv.custom_values.locale.json'
import Dashboard from '../module/sv.dashboard.locale.json'
import EditEmailTemplate from '../module/sv.edit_email_template.locale.json'
import Editor from '../module/sv.editor.locale.json'
import EmojiPicker from '../module/sv.emoji_picker.locale.json'
import InstagramModal from '../module/sv.instagram_modal.locale.json'
import InstagramStoryOptions from '../module/sv.instagram_story_options.locale.json'
import ListPost from '../module/sv.list_post.locale.json'
import LocationTagging from '../module/sv.location_tagging.locale.json'
import Pinterest from '../module/pinterest/ptBR.pinterest.locale.json'
import Planner from '../module/sv.planner.locale.json'
import PostPreview from '../module/sv.post_preview.locale.json'
import RecurringPostFormRules from '../module/sv.recurring_post_form_rules.locale.json'
import ScheduleRecurringModal from '../module/sv.schedule_recurring_modal.locale.json'
import SetEmailTemplate from '../module/sv.set_email_template.locale.json'
import Settings from '../module/sv.settings.locale.json'
import SocialAccountsDropDownLocale from '../module/sv.social_accounts_dropdown.locale.json'
import Statistics from '../module/sv.statistics.locale.json'
import TagsAndCategory from '../module/sv.tags_and_category.locale.json'
import TikTokOption from '../module/sv.tiktok_option.locale.json'
import UserSelect from '../module/sv.user_select.locale.json'
import VideoPreview from '../module/sv.video_preview.locale.json'
import Watermark from '../module/sv.watermark.locale.json'
import WeekDays from '../module/sv.week_days.locale.json'
import Welcome from '../module/sv.welcome.locale.json'
import Youtube from '../module/sv.youtube.locale.json'
import addGroupModal from '../module/sv.add_group_modal.locale.json'

export default {
  sv: {
    ...CommonLocale,
    ...SocialAccountsDropDownLocale,
    ...addGroupModal,
    ...AddSocialAccountModal,
    ...ConnectModal,
    ...CreatePost,
    ...CsvPost,
    ...CustomValues,
    ...EditEmailTemplate,
    ...EmojiPicker,
    ...InstagramModal,
    ...InstagramStoryOptions,
    ...Planner,
    ...PostPreview,
    ...SetEmailTemplate,
    ...Settings,
    ...UserSelect,
    ...VideoPreview,
    ...WeekDays,
    ...Welcome,
    ...Dashboard,
    ...ScheduleRecurringModal,
    ...ListPost,
    ...RecurringPostFormRules,
    ...Editor,
    ...TagsAndCategory,
    ...Watermark,
    ...CreateRssPost,
    ...CreateReviewPost,
    ...TikTokOption,
    ...Youtube,
    ...Pinterest,
    ...LocationTagging,
    ...Statistics,
  },
}
