import { IMedia, MediaErrors } from '@/types/media'

import { i18n } from '../../../../locales'
import { ImageBase } from './base'

export class InstagramStory extends ImageBase {
  /**
   * Max image size supported 10 MB
   */
  readonly maxSize = 8000000

  /**
   * Platform Name
   */
  readonly platform = 'Instagram Story'

  /**
   * supported image formats
   */
  readonly imageFormat = ['image/jpeg', 'image/jpg']

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
    this.typeError()
    this.maxSizeError()
  }

  protected maxSizeError(): void {
    if (this.image.size && this.image.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidImage.size', {
          platform: this.platform,
          limit: '8MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  protected typeError(): void {
    if (this.image.type && !this.imageFormat.includes(this.image.type)) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.formatTitle'),
        message: i18n.global.t('createPost.invalidImage.onlyJpegFormat', {
          platform: this.platform,
          format: this.getImageExtension(),
        }),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }

  protected aspectRatioError(): void {
    // do nothing
  }
}
