<script setup lang="ts">
import {
  AlertTriangleIcon,
  CheckIcon,
  SearchLgIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIAvatar,
  UIButton,
  UIDivider,
  UIInput,
  UIModal,
  UIModalContent,
  UIModalHeader,
  UISpace,
  UISpin,
  UITextSmNormal,
  UITextXsRegular,
} from '@gohighlevel/ghl-ui'
import { PropType, computed, h, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import communityIcon from '../../assets/img/icon-community.svg'
import userIcon from '../../assets/img/user.svg'
import { useNotification } from '../../compositions/useNotification'
import { CommunityService } from '../../service/CommunityService'
import { useSocialMediaStore } from '../../store'

export interface Group {
  id: string
  name: string
  avatar: string
  slug?: string
  role?: string
  isConnected?: boolean
  error?: boolean
  helpMessage?: string
  helpUrl?: string
}

const props = defineProps({
  showModal: {
    type: Boolean,
    default: true,
  },
  oauthId: {
    type: String,
    default: '',
  },
  reconnectFilter: {
    type: Boolean,
    default: false,
  },
  reconnectAccounts: {
    type: Array as PropType<any[]>,
    default() {
      return []
    },
  },
})

const { t } = useI18n()
const store = useSocialMediaStore()
const notification = useNotification()
const emits = defineEmits(['close', 'refresh'])
const showModal = computed(() => props.showModal)
const groups = ref<Group[]>([])
const searchWord = ref('')
const loader = ref(false)
const saveLoader = ref(false)

const searchGroups = computed(() => {
  if (!searchWord.value) {
    return groups.value
  } else {
    const inputValue = searchWord.value.toUpperCase()
    return groups.value.filter(
      (x: Group) => x.name.toUpperCase().indexOf(inputValue) > -1
    )
  }
})

const placeholder = computed(() => {
  const str = t('community.connectModal.searchPlaceholder')
  return str.length > 48 ? `${str.substring(0, 48)}...` : str
})

watch(showModal, async () => {
  if (props.showModal) {
    loader.value = true

    groups.value = []
    const { data } = await CommunityService.finishCommunityOAuth(
      store.locationId,
      store.userId
    )
    groups.value = data.accounts
    loader.value = false
  }
})

function closeModal() {
  searchWord.value = ''
  emits('refresh')
  emits('close')
}

async function addAccount(group: Group) {
  try {
    loader.value = true

    const body = {
      id: group.id,
      name: group.name,
      slug: group.slug,
      avatar: group.avatar,
      userId: store.userId,
    }
    saveLoader.value = true
    const addAccountRes = await CommunityService.addCommunityAccount(
      store.locationId,
      body
    )

    if (!addAccountRes.data.length) {
      notification.create(
        {
          type: 'error',
          title: t('community.connectModal.errorTitle'),
          content: t('community.connectModal.errorNoChannelsDescription'),
        },
        8000
      )
    }

    groups.value = []
    const { data } = await CommunityService.fetchCommunityAccounts(
      store.locationId
    )
    store.getAllowedAccounts()

    groups.value = data
    saveLoader.value = false
    loader.value = false
  } catch (error) {
    loader.value = false
    // console.log(error)
  }
}

function icon() {
  return h('img', {
    src: communityIcon,
    class: 'w-8 h-8',
  })
}
</script>
<template>
  <UIModal
    :show="showModal"
    id="modal"
    :width="565"
    className="custom-social-modal"
  >
    <template #header>
      <UIModalHeader
        id="modal-header"
        :title="$t('community.connectModal.title')"
        description=""
        :icon="icon"
        @close="closeModal"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <div>
        <UIInput
          id="search-box"
          type="text"
          v-model:model-value="searchWord"
          :placeholder="placeholder"
        >
          <template #prefix>
            <SearchLgIcon class="w-4 h-5 text-gray-400" />
          </template>
        </UIInput>
        <UIDivider />
        <div
          v-if="loader"
          class="flex flex-col space-y-4 justify-center items-center"
        >
          <UITextXsRegular v-if="saveLoader" class="text-gray-600">{{
            $t('community.connectModal.addGroupLoaderMessage')
          }}</UITextXsRegular>
          <UISpin size="medium"></UISpin>
        </div>
        <div v-else-if="!loader" class="max-h-[450px] overflow-y-auto pr-3">
          <UITextSmNormal>{{
            $t('community.connectModal.groupTitle')
          }}</UITextSmNormal>
          <div
            v-for="group of searchGroups"
            :key="group.id"
            :vertical="true"
            size="large"
            class="pt-4"
          >
            <UISpace
              class="items-center"
              :class="{
                'opacity-50': group.error,
              }"
              justify="space-between"
            >
              <div class="w-[350px]">
                <UISpace size="large" class="items-center">
                  <UIAvatar
                    :src="group.avatar"
                    class="h-8 w-8"
                    :fallbackSrc="userIcon"
                  />
                  <UITextSmNormal class="w-[300px]">{{
                    group.name
                  }}</UITextSmNormal>
                </UISpace>
              </div>
              <div class="w-[120px] flex justify-end">
                <UISpace
                  v-if="group.isConnected"
                  class="items-center text-success-600"
                >
                  <CheckIcon class="w-4 h-4" />
                  <UITextSmNormal>{{ $t('common.connected') }}</UITextSmNormal>
                </UISpace>
                <UIButton
                  id="add-page-community"
                  v-else-if="!props.reconnectAccounts.length"
                  type="primary"
                  @click.prevent="addAccount(group)"
                  >{{ $t('common.add') }}</UIButton
                >
              </div>
            </UISpace>
            <UISpace size="large" class="items-center" v-if="group.error">
              <AlertTriangleIcon class="h-8 w-8 text-red-400" />
              <p class="text-xs text-gray-400 w-[420px]">
                {{ group.helpMessage }}
              </p>
            </UISpace>
          </div>
          <div
            v-if="!searchGroups.length"
            class="w-full my-2 text-red-500 text-sm"
          >
            {{ $t('community.connectModal.noSearchedProfileError') }}
          </div>
        </div>
      </div>
    </UIModalContent>
  </UIModal>
</template>
