import { IMedia, MediaErrors } from '../../../../types/media'

import { i18n } from '../../../../locales'
import { DisplayPlatform } from '../../../../types'
import { VideoBase } from './base'

export class Community extends VideoBase {
  /**
   * Max video size supported 1 GB
   */
  readonly maxSize = 1000 * 1000 * 1000

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 4

  /**
   * Supported video format
   */
  readonly supportedFormat = ['video/mp4']

  /**
   * Platform name to be display
   */
  readonly platform = DisplayPlatform.COMMUNITY

  constructor(media: IMedia) {
    super(media)
    this.typeError()
    this.durationError()
    this.sizeError()
  }

  protected typeError(): void {
    if (!this.supportedFormat.includes(this.video.type || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('community.invalidVideo.videoType', {
          format: this.getVideoExtension(),
        }),
        invalidType: MediaErrors.VIDEO_TYPE,
      })
    }
  }

  protected durationError(): void {
    if (!this.video.duration || this.video.duration < this.minDuration) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('community.invalidVideo.duration', {
          platform: this.platform,
          duration: this.video.displayDuration,
        }),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected sizeError(): void {
    if (!this.video.size || this.video.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('community.invalidVideo.size', {
          platform: this.platform,
          size: this.video.size ? (this.video.size / 1000000).toFixed(2) : '0',
        }),
        invalidType: MediaErrors.VIDEO_SIZE,
      })
    }
  }

  protected videoCodecError(): void {
    // Do nothing
  }

  protected aspectRatioError(): void {
    // Do nothing
  }

  protected resolutionError(): void {
    // Do nothing
  }

  protected audioCodecNameError(): void {
    // Do nothing
  }

  protected audioChannelError(): void {
    // Do nothing
  }

  protected frameRateError(): void {
    // Do nothing
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    // Do nothing
  }

  protected videoBitrateError(): void {
    // Do nothing
  }
}
