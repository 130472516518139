import { useNotification as naiveNotification } from '@gohighlevel/ghl-ui'
import { NotificationOptions } from 'naive-ui'

export function useNotification() {
  const notification = naiveNotification()

  const create = (props: NotificationOptions, NotificationDuration = 3000) =>
    notification.create({
      ...props,
      duration: NotificationDuration,
    })

  return {
    create,
  }
}
