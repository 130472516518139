import { i18n } from '../../../../locales'
import { IMedia, MediaErrors } from '../../../../types/media'
import { VideoBase } from './base'

export class Twitter extends VideoBase {
  /**
   * Max video size supported 512 MB
   */
  readonly maxSize = 100 * 1000 * 5000

  /**
   * Max supported duration in seconds
   */
  readonly maxDuration = 140

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 0.5

  /**
   * Supported video format
   */
  readonly supportedFormat = ['video/mov', 'video/mp4']

  /**
   * Platform name to be display
   */
  readonly platform = 'Twitter'

  constructor(media: IMedia) {
    super(media)
    this.typeError()
    this.sizeError()
    this.durationError()
  }

  protected typeError(): void {
    if (!this.supportedFormat.includes(this.video.type || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.format', {
          platform: this.platform,
          format: this.getVideoExtension(),
        }),
        invalidType: MediaErrors.VIDEO_TYPE,
      })
    }
  }

  protected durationError(): void {
    if (
      !this.video.duration ||
      this.video.duration > this.maxDuration ||
      this.video.duration < this.minDuration
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.twitterDuration', {
          duration: this.video.displayDuration,
        }),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected sizeError(): void {
    if (!this.video.size || this.video.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.twitterSize', {
          size: this.video.size ? (this.video.size / 1000000).toFixed(2) : '0',
        }),
        invalidType: MediaErrors.VIDEO_SIZE,
      })
    }
  }

  protected resolutionError(): void {
    // Do nothing
  }

  protected audioCodecNameError(): void {
    // Do nothing
  }

  protected audioChannelError(): void {
    // Do nothing
  }

  protected videoCodecError(): void {
    // Do nothing
  }

  protected frameRateError(): void {
    // Do nothing
  }

  protected aspectRatioError(): void {
    // Do nothing
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    // Do nothing
  }

  protected videoBitrateError(): void {
    // Do nothing
  }
}
