import { IMedia } from '.@/types/media'
import { ImageBase } from './base'
import { Community } from './community'
import { FacebookPost } from './facebook'
import { Google } from './google'
import { InstagramPost } from './instagramPost'
import { InstagramReel } from './instagramReel'
import { InstagramStory } from './instagramStory'
import { Linkedin } from './linkedin'
import { Pinterest } from './pinterest'
import { TikTok } from './tiktok'
import { Twitter } from './twitter'
import { Youtube } from './youtube'

export class ImageValidator extends ImageBase {
  /**
   * Facebook image error handler
   */
  facebookPost: FacebookPost

  /**
   * Google My Business image error handler
   */
  google: Google

  /**
   * Linkedin image error handler
   */
  linkedin: Linkedin

  /**
   * Linkedin image error handler
   */
  twitter: Twitter

  /**
   * Instagram Professional post image error handler
   */
  instagramPost: InstagramPost

  /**
   * Instagram Story image error handler
   */
  instagramStory: InstagramStory

  /**
   * Instagram Reel image error handler
   */
  instagramReel: InstagramReel

  /**
   * TikTok image error handler
   */
  tikTok: TikTok

  /**
   * Youtube image error handler
   */
  youtube: Youtube

  /**
   * Pinterest image error handler
   */
  pinterest: Pinterest

  /**
   * Community image error handler
   */
  community: Community

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, true)
    this.facebookPost = new FacebookPost(media, isThumbnail)
    this.google = new Google(media, isThumbnail)
    this.linkedin = new Linkedin(media, isThumbnail)
    this.twitter = new Twitter(media, isThumbnail)
    this.instagramPost = new InstagramPost(media, isThumbnail)
    this.instagramStory = new InstagramStory(media, isThumbnail)
    this.instagramReel = new InstagramReel(media, isThumbnail)
    this.tikTok = new TikTok(media, isThumbnail)
    this.youtube = new Youtube(media, isThumbnail)
    this.pinterest = new Pinterest(media, isThumbnail)
    this.community = new Community(media, isThumbnail)
  }

  getError() {
    return {
      error: this.getBaseError(),
      facebook: {
        post: this.facebookPost.getError(),
      },
      instagram: {
        post: this.instagramPost.getError(),
        story: this.instagramStory.getError(),
        reel: this.instagramReel.getError(),
      },
      linkedin: this.linkedin.getError(),
      twitter: this.twitter.getError(),
      google: this.google.getError(),
      tiktok: this.tikTok.getError(),
      youtube: this.youtube.getError(),
      pinterest: this.pinterest.getError(),
      community: this.community.getError(),
    }
  }

  protected aspectRatioError(): void {
    // Do nothing
  }
}
