import { i18n } from '../../../../locales'
import { IMedia, MediaErrors } from '../../../../types/media'
import { VideoBase } from './base'

export class FacebookPost extends VideoBase {
  /**
   * Max video size supported 1 GB
   */
  readonly maxSize = 100 * 1000 * 10000

  /**
   * Max supported duration in seconds
   */
  readonly maxDuration = 14400

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 1

  constructor(media: IMedia) {
    super(media)
    this.sizeError()
    this.durationError()
  }

  protected typeError(): void {
    // Do nothing
  }

  protected durationError(): void {
    if (
      !this.video.duration ||
      this.video.duration > this.maxDuration ||
      this.video.duration < this.minDuration
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.facebookDuration', {
          duration: this.video.displayDuration,
        }),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected sizeError(): void {
    if (!this.video.size || this.video.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.facebookSize', {
          size: this.video.size
            ? (this.video.size / 1000000000).toFixed(2)
            : '0',
        }),
        invalidType: MediaErrors.VIDEO_SIZE,
      })
    }
  }

  protected resolutionError(): void {
    // Do nothing
  }

  protected audioCodecNameError(): void {
    // Do nothing
  }

  protected audioChannelError(): void {
    // Do nothing
  }

  protected videoCodecError(): void {
    // Do nothing
  }

  protected frameRateError(): void {
    // Do nothing
  }

  protected aspectRatioError(): void {
    // Do nothing
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    // Do nothing
  }

  protected videoBitrateError(): void {
    // Do nothing
  }
}
