import '@gohighlevel/ghl-canvas/dist/ghl-canvas.css'
import '@gohighlevel/ghl-ui/dist/style.css'
import 'v-calendar/dist/style.css'
import './assets/index.css'

import mitt from 'mitt'
import { createPinia } from 'pinia'
import VCalendar from 'v-calendar'
import { createApp } from 'vue'
import lazyLoad from 'vue-lazyload'
import App from './App.vue'
import { i18n } from './locales'
import router from './route'
import { setupSentry } from './util/setupSentry'

const app = createApp(App)

app.use(createPinia())
app.use(i18n)

app.use(router)

const emitter = mitt()
app.config.globalProperties.emitter = emitter

app.use(VCalendar)
app.use(lazyLoad, {
  preLoad: 1.3,
  error: '',
  loading: '',
  attempt: 1,
})

app.mount('#app')
const env = process.env.NODE_ENV ?? ''
setupSentry(app, router, env)
app.config.performance = env === 'development' || env === 'staging'
