import axios, { AxiosRequestConfig } from 'axios'

import config from '../config'
import { useSocialMediaStore } from '../store'
import { setupAutoUpdateToken } from '../util/setupToken'
import { ContentAIService } from './ContentAIService'
import { CsvImportService } from './CsvImportService'
import { CustomDataService } from './CustomDataService'
import { DefaultService } from './DefaultService'
import { EmailService } from './EmailService'
import { FacebookService } from './FacebookService'
import { GmbService } from './GmbService'
import { HashtagService } from './HashtagService'
import { LinkedinService } from './LinkedinService'
import { MediaService } from './MediaService'
import { NotificationService } from './NotificationService'
import { PublicApiService } from './PublicService'
import { RecurringPostService } from './RecurringPostService'
import { ReviewPostService } from './ReviewPostService'
import { RssPostService } from './RssPostService'
import { TagsAndCategoryService } from './TagsAndCategoryService'
import { TwitterService } from './TwitterService'
import { WatermarkService } from './WatermarkService'
import { OAuthService } from './oAuthService'

const getHeader = () => {
  // Add developer_version when version deployment is done for backend
  return {
    channel: 'APP',
    source: 'WEB_USER',
    version: '2021-07-28',
    // developer_version: 'design-ayush',
  }
}

export const requests = (
  host?: string,
  useNewToken?: boolean,
  cancelToken?: AbortSignal
) => {
  const baseUrl = host || config.restApiUrl
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
    signal: cancelToken,
  })

  axiosInstance.interceptors.request.use(
    async (requestConfig: AxiosRequestConfig) => {
      try {
        const store = useSocialMediaStore()
        requestConfig.headers = requestConfig.headers || {}
        if (useNewToken) {
          requestConfig.headers['Authorization'] = `Bearer ${store.authTokenV2}`
        }
        requestConfig.headers = {
          ...requestConfig.headers,
          'token-id': store.tokenId || '',
        }
      } catch (e) {}
      return requestConfig
    }
  )
  setupAutoUpdateToken(axiosInstance)
  return axiosInstance
}

export {
  ContentAIService,
  CsvImportService,
  CustomDataService,
  DefaultService,
  EmailService,
  FacebookService,
  GmbService,
  HashtagService,
  LinkedinService,
  MediaService,
  NotificationService,
  OAuthService,
  PublicApiService,
  RecurringPostService,
  ReviewPostService,
  RssPostService,
  TagsAndCategoryService,
  TwitterService,
  WatermarkService,
}
