<script setup lang="ts">
import {
  UIButton,
  UICard,
  UIModal,
  UIModalContent,
  UIModalHeader,
  UITextMdMedium,
  UITextSmNormal,
} from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'
import { useSocialMediaStore } from '../../store'

const prop = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const emit = defineEmits(['close'])
const store = useSocialMediaStore()

const btnArgs = {
  id: 'mdl_tiktok-select-personal',
  type: 'primary',
  size: 'large',
  default: t('common.select'),
}
const btnBusnissArgs = {
  id: 'mdl_tiktok-select-business',
  type: 'primary',
  size: 'large',
  default: t('common.select'),
}

function connectPersonalAccount() {
  store.connectAccount('tiktok')
  closeModal()
}
function connectBusinessAccount() {
  store.connectAccount('tiktok-business')
  closeModal()
}

function closeModal() {
  emit('close')
}
</script>
<template>
  <UIModal :show="showModal" :width="510">
    <template #header>
      <UIModalHeader
        id="modal-header"
        type="success"
        :title="$t('createPost.tikTokModal.title')"
        :description="$t('createPost.tikTokModal.description')"
        @close="closeModal"
      >
      </UIModalHeader>
    </template>
    <!--  content goes here  -->
    <UIModalContent id="modal-content-tiktok-select">
      <UICard class="flex flex-col justify-between">
        <template #header>
          <div class="flex items-center justify-center" style="height: 103px">
            <img src="/src/assets/img/icon-tiktok-personal.png" />
          </div>
        </template>

        <UITextMdMedium>{{
          $t('createPost.tikTokModal.individualTitle')
        }}</UITextMdMedium>
        <UITextSmNormal>
          {{ $t('createPost.tikTokModal.individualDescription') }}
        </UITextSmNormal>

        <template #footer>
          <div class="p-4 flex justify-center">
            <UIButton v-bind="btnArgs" @click="connectPersonalAccount">{{
              $t('common.select')
            }}</UIButton>
          </div>
        </template>
      </UICard>

      <UICard class="flex flex-col justify-between">
        <template #header>
          <div class="flex items-center justify-center" style="height: 103px">
            <img src="/src/assets/img/icon-tiktok-business.png" />
          </div>
        </template>

        <UITextMdMedium>{{
          $t('createPost.tikTokModal.businessTitle')
        }}</UITextMdMedium>
        <UITextSmNormal>
          {{ $t('createPost.tikTokModal.businessDescription') }}
        </UITextSmNormal>

        <template #footer>
          <div class="p-4 flex justify-center">
            <UIButton v-bind="btnBusnissArgs" @click="connectBusinessAccount">{{
              $t('common.select')
            }}</UIButton>
          </div>
        </template>
      </UICard>
    </UIModalContent>
  </UIModal>
</template>
<style lang="scss">
#modal-content-tiktok-select {
  @apply flex flex-row space-x-4;

  .hl-card {
    max-width: 222px;
    min-width: 222px;

    &.individual {
      @apply border-primary-600 bg-primary-50 border;

      .hl-card-header {
        @apply bg-primary-50 rounded-2xl;
      }

      .hl-card-footer {
        @apply bg-primary-50 rounded-2xl;
      }

      .hl-card-content {
        @apply bg-primary-50;
      }
    }

    .hl-card-header {
      @apply border-b-0;
    }

    .hl-card-footer {
      @apply border-t-0;
    }

    .hl-card-content {
      @apply flex flex-col items-center text-center;
      min-height: 145px;
    }
  }
}
</style>
