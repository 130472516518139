import { IMedia, MediaErrors } from '@/types/media'

import { i18n } from '../../../../locales'
import { DisplayPlatform } from '../../../../types'
import { ImageBase } from './base'

export class Pinterest extends ImageBase {
  /**
   * Platform Name
   */
  readonly platform = DisplayPlatform.PINTEREST
  /**
   * supported image formats
   */
  readonly imageFormat = [
    'image/bmp',
    'image/jpeg',
    'image/png',
    'image/tiff',
    'image/webp',
  ]

  /**
   * supported thumbnail formats
   */
  readonly thumbnailFormat = ['image/jpeg', 'image/png']

  /**
   * Max image size supported 10 MB
   */
  readonly maxSize = 10000000

  /**
   * Max thumbnail image size supported 10 MB
   */
  readonly maxThumbnailSize = 10000000

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
    if (isThumbnail) {
      this.thumbnailTypeError()
      this.maxThumbnailSizeError()
    } else {
      this.imageTypeError()
      this.maxSizeError()
    }
  }

  protected maxSizeError(): void {
    if (!this.isThumbnail && this.image.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidImage.size', {
          limit: '20MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  protected thumbnailTypeError(): void {
    if (
      this.isThumbnail &&
      this.image.type &&
      !this.thumbnailFormat.includes(this.image.type)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.formatTitle'),
        message: i18n.global.t('createPost.invalidImage.onlyJpegPngFormat', {
          platform: this.platform,
          format: this.getImageExtension(),
        }),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }

  protected maxThumbnailSizeError(): void {
    if (this.isThumbnail && this.image.size > this.maxThumbnailSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidImage.size', {
          limit: '10MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  protected imageTypeError(): void {
    if (
      !this.isThumbnail &&
      this.image.type &&
      !this.imageFormat.includes(this.image.type)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.formatTitle'),
        message: i18n.global.t('pinterest.invalidImage.notSupportedFormat', {
          platform: this.platform,
          format: this.getImageExtension(),
        }),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }

  protected aspectRatioError(): void {
    // Do nothing
  }
}
