import { requests } from '.'
import config from '../config'
import {
  IAddWatermark,
  ICreateWatermarkTemplate,
  IUpdateWatermarkTemplate,
} from '../types/watermark'

export const WatermarkService = {
  createWatermark: (locationId: string, body: ICreateWatermarkTemplate) =>
    requests(config.socialMediaServiceUrl).post(
      `/${locationId}/watermarks`,
      body
    ),
  getWatermarkList: (
    locationId: string,
    skip?: number,
    limit?: number,
    query?: string
  ) => {
    if (!limit) limit = 10
    if (!skip) skip = 0
    return requests(config.socialMediaServiceUrl).get(
      `/${locationId}/watermarks?skip=${skip}&limit=${limit}${
        query ? `&q=${query}` : ''
      }`
    )
  },
  getWatermark: (locationId: string, templateId: string) =>
    requests(config.socialMediaServiceUrl).get(
      `/${locationId}/watermarks/${templateId}`
    ),
  updateWatermark: (
    locationId: string,
    templateId: string,
    body: IUpdateWatermarkTemplate
  ) =>
    requests(config.socialMediaServiceUrl).put(
      `/${locationId}/watermarks/${templateId}`,
      body
    ),
  deleteWatermark: (locationId: string, templateId: string) =>
    requests(config.socialMediaServiceUrl).delete(
      `/${locationId}/watermarks/${templateId}`
    ),
  addWatermarkOnImage: (locationId: string, body: IAddWatermark) =>
    requests(config.socialMediaServiceUrl).post(
      `/${locationId}/watermarks/add-image-watermark`,
      body
    ),
  enqueueWatermark: (locationId: string, body: IAddWatermark) =>
    requests(config.socialMediaServiceUrl).post(
      `/${locationId}/watermarks/enqueue-watermark`,
      body
    ),
  watermarkProgress: (locationId: string, taskId: string) =>
    requests(config.socialMediaServiceUrl).get(
      `/${locationId}/watermarks/check-progress/${taskId}`
    ),
}
