import { createRouter, createWebHistory } from 'vue-router'

import { useSocialMediaStore } from './../store/index'

const routes = [
  {
    path: '/v2/location/:locationId/marketing/social-planner',
    name: 'home',
    // component: () => import('../pages/Home.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/social-planner/list',
    name: 'list',
    component: () => import('../pages/Settings.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/social-planner/post',
    name: 'post',
    component: () => import('../pages/Post.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/social-planner/post/csv/import/:id',
    name: 'post-csv',
    component: () => import('../pages/CsvPost.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/social-planner/post/:id',
    name: 'post-edit',
    component: () => import('../pages/Post.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/social-planner/planner',
    name: 'planner',
    component: () => import('../pages/Planner.vue'),
  },
  {
    path: '/v2/location/:locationId/marketing/social-planner/statistics',
    name: 'statistics',
    component: () => import('../pages/PostStatistics.vue'),
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to, from) => {
  const store = useSocialMediaStore()
  try {
    if (store.changeRoute) {
      store.setChangeRouteFlag(false)
    } else if (store.appHandshake) {
      //@ts-ignore
      store.appHandshake.then(parent => {
        parent.emit('spm-ts', {
          router: to.fullPath,
        })
      })
    }
  } catch (error) {}
})

export default router
