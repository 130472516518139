import { requests } from '.'
import config from '../config'

export const PinterestService = {
  fetchPinterestAccounts: (locationId: string, accountId: string) =>
    requests(config.socialMediaServiceUrl)
      .get(`/oauth/${locationId}/pinterest/accounts/${accountId}`)
      .then(response => ({ ...response, data: response.data.results })),
  attachPinterestAccount: (locationId: string, accountId: string, body: any) =>
    requests(config.socialMediaServiceUrl)
      .post(`/oauth/${locationId}/pinterest/accounts/${accountId}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  getBoardList: (
    locationId: string,
    accountId: string,
    query: { limit: number; bookmark?: string | null }
  ) =>
    requests(config.socialMediaServiceUrl)
      .get(`/oauth/${locationId}/pinterest/boards/${accountId}`, {
        params: query,
      })
      .then(response => ({ ...response, data: response.data.results })),
  getBoardDetails: (
    locationId: string,
    accountId: string,
    query: { boardId: string }
  ) =>
    requests(config.socialMediaServiceUrl)
      .get(`/oauth/${locationId}/pinterest/boards/${accountId}`, {
        params: query,
      })
      .then(response => ({ ...response, data: response.data.results })),
  createBoard: (
    locationId: string,
    accountId: string,
    body: { name: string; privacy: string }
  ) =>
    requests(config.socialMediaServiceUrl)
      .post(`/oauth/${locationId}/pinterest/board/${accountId}`, body)
      .then(response => ({ ...response, data: response.data.results })),
}
