<script setup lang="ts">
import { UIContentWrap, UISpin } from '@gohighlevel/ghl-ui'
import Postmate from 'postmate'
import { onMounted, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import useEmitter from './compositions/useEmitter'
import { useSocialMediaStore } from './store'
import {
  AccountType,
  ConnectAccount,
  Platform,
  QuickActionSubmitOptions,
} from './types'
import { MediaEditResponse } from './types/media'
import { setupPendo } from './util/pendoConfig'
import { updateParent } from './util/updateParent'

// Global Modal
import { NNotificationProvider } from 'naive-ui'
import AddAccount from './components/modal/AddAccount.vue'
import ConnectCommunityAccount from './components/modal/ConnectCommunityAccount.vue'
import ConnectInstagramAccountInfo from './components/modal/ConnectInstagramAccountInfo.vue'
import TiktokModal from './components/modal/TiktokModal.vue'
import ConnectFacebookAccount from './components/modal/connect/ConnectFacebookAccount.vue'
import ConnectGmbLocation from './components/modal/connect/ConnectGmbLocation.vue'
import ConnectInstagramAccount from './components/modal/connect/ConnectInstagramAccount.vue'
import ConnectLinkedinAccount from './components/modal/connect/ConnectLinkedinAccount.vue'
import ConnectPinterestAccount from './components/modal/connect/ConnectPinterestAccount.vue'
import ConnectTiktokAccount from './components/modal/connect/ConnectTiktokAccount.vue'
import ConnectTwitterAccount from './components/modal/connect/ConnectTwitterAccount.vue'
import ConnectYoutubeAccount from './components/modal/connect/ConnectYoutubeAccount.vue'

import moment from 'moment-timezone'
import { CustomDataService, WatermarkService } from './service'
import { setSentryMeta } from './util/setupSentry'

const store = useSocialMediaStore()
const router = useRouter()
const eventBus = useEmitter()
const i18n = useI18n()

const authenticatedUser = ref(false)
const showAddAccount = ref(false)
const showTiktokModal = ref(false)
const fetchingCustomValues = ref(false)
const showConnectFacebookModal = ref(false)
const showConnectInstagramModal = ref(false)
const showConnectTwitterModal = ref(false)
const showConnectTiktokModal = ref(false)
const showConnectLinkedinModal = ref(false)
const showConnectGmbModal = ref(false)
const showConnectYoutubeModal = ref(false)
const showConnectPinterestModal = ref(false)
const showConnectCommunityModal = ref(false)
const connectPlatform = ref<Platform>()
const oAuthId = ref<string>('')
const reconnectAccounts = ref()
const reconnectFilter = ref(false)
const showConnectInstagramInfoModal = ref(false)
const fetchingWatermarkTemplate = ref(false)
const tiktokAccountType = ref(AccountType.PROFILE)
const accountError = ref<string>('')

onMounted(() => {
  if (process.env.NODE_ENV === 'development') {
    Postmate.debug = true
  }
  const handshake = new Postmate.Model({
    'updated-token': (token: string) => store.refreshToken(token),
    'init-planner': (initialised: boolean) =>
      store.initialisedPlanner(initialised),
    'update-connection': (data: any) => {
      store.updateConnection(data)
    },
    'change-route': async (data: any) => {
      store.setChangeRouteFlag(data.changeRoute)
      router.push(data.route)
    },
    'refresh-csv-list': () => {
      eventBus.emit('uploaded-csv')
    },
    'connect-account': (data: ConnectAccount) => {
      if (data.refresh == 'true') {
        eventBus.emit('connection-refreshed', {
          platform: data.platform,
          oAuthId: data.oAuthId,
        })
      } else {
        connectAccount(data)
      }
    },
    'refresh-content-ai': (id: string) => {
      eventBus.emit('refresh-content-ai', id)
    },
    'add-content-ai-image': (id: string) => {
      eventBus.emit('add-content-ai-image', id)
    },
    'add-quick-action-variation': (data: {
      submitType: QuickActionSubmitOptions
      quickActionSessionId: string
    }) => {
      eventBus.emit('add-quick-action-variation', data)
    },
    route: async (data: any) => {
      store.setChangeRouteFlag(true)
      router.push(data)
    },
    'refresh-email-template': (editEmailTemplateClosed: boolean) => {
      if (editEmailTemplateClosed) eventBus.emit('edit-email-template-closed')
    },
    'social_planner--image_editor--edited--image': (
      mediaEdited: MediaEditResponse
    ) => {
      eventBus.emit('social_planner--image_editor--edited--image', mediaEdited)
    },
  })

  handshake.then(({ model }: any) => {
    store.init(model, handshake)
    setSentryMeta({
      userId: store.userId,
      companyId: store.companyId,
      locationId: store.locationId,
      email: store.locationUsers.find(u => u.id === store.userId)?.email,
      locale: store.locale,
    })
    setLocales(model.locale)
    updateParent('track-event', {
      event: 'Social Planner Visited',
      locationId: store.locationId,
    })
    if (!model.accounts.length && router.currentRoute.name !== 'home') {
      router.push({
        name: 'home',
        params: {
          location_id: store.locationId,
        },
      })
    }
    setupPendo({
      companyId: model.companyId,
      userId: model.userId,
      locationId: model.locationId,
    })
    setCustomVariables()
    setWatermarkTemplate()
  })

  eventBus.on('showAddAccount', () => {
    showAddAccount.value = true
  })

  eventBus.on('showTiktokModal', () => {
    showTiktokModal.value = true
  })

  eventBus.on('connectModal', (platform: Platform) => {
    const data = {
      platform,
      reconnectFilter: true,
    }
    connectAccount(data)
  })

  eventBus.on('ConnectInstagramAccountInfo', () => {
    showConnectInstagramInfoModal.value = true
  })

  eventBus.on('ConnectCommunityAccount', () => {
    showConnectCommunityModal.value = true
  })
})

watchEffect(() => {
  if (store.tokenId) {
    authenticatedUser.value = true
  }
})

function closeAddAccount() {
  if (store.accounts.length < 1) {
    return
  }
  showAddAccount.value = false
}

async function setCustomVariables() {
  if (fetchingCustomValues.value) return
  try {
    fetchingCustomValues.value = true

    const { data } = await CustomDataService.fetchCustomValues(store.locationId)

    store.setCustomData([
      ...data.static,
      {
        label: 'Custom Values',
        value: '__custom-values__',
        children: data['custom-values'],
      },
    ])
  } catch (error) {
    console.error(error)
  } finally {
    fetchingCustomValues.value = false
  }
}

async function setWatermarkTemplate() {
  if (fetchingWatermarkTemplate.value) return
  try {
    fetchingWatermarkTemplate.value = true

    const { data } = await WatermarkService.getWatermarkList(store.locationId)
    if (data?.watermarks?.length > 0) {
      store.setWatermarkTemplateId(data?.watermarks[0]['_id'])
    }
  } catch (error) {
    console.error(error)
  } finally {
    fetchingWatermarkTemplate.value = false
  }
}

function setLocales(lang: string) {
  let locale = lang
  locale = locale.replace('_', '-')
  i18n.locale.value = locale
  if (locale !== 'en-US' && i18n.messages.value[locale]?.moment) {
    const momentLocales: any = i18n.messages.value[locale].moment
    locale = locale.includes('fr') ? 'fr' : locale
    moment.locale(locale.toLocaleLowerCase(), momentLocales)
  }
}

function connectAccount(data: ConnectAccount) {
  try {
    connectPlatform.value = data.platform
    oAuthId.value = data.oAuthId ? data.oAuthId : ''
    reconnectFilter.value = data.reconnectFilter ? data.reconnectFilter : false
    reconnectAccounts.value =
      data.reconnectAccounts === 'undefined' || !data.reconnectAccounts
        ? []
        : data.reconnectAccounts
    accountError.value = data.error?.type ? data.error.type : ''

    switch (connectPlatform.value) {
      case Platform.GOOGLE:
        showConnectGmbModal.value = true
        break

      case Platform.FACEBOOK:
        showConnectFacebookModal.value = true
        break

      case Platform.INSTAGRAM:
        showConnectInstagramModal.value = true
        break

      case Platform.LINKEDIN:
        showConnectLinkedinModal.value = true
        break

      case Platform.TIKTOK:
        showConnectTiktokModal.value = true
        tiktokAccountType.value = AccountType.PROFILE
        break

      case Platform.TIKTOK_BUSINESS:
        showConnectTiktokModal.value = true
        tiktokAccountType.value = AccountType.BUSINESS
        break

      case Platform.TWITTER:
        showConnectTwitterModal.value = true
        break

      case Platform.YOUTUBE:
        showConnectYoutubeModal.value = true
        break
      case Platform.PINTEREST:
        showConnectPinterestModal.value = true
        break
    }
  } catch (error) {
    console.log(error)
  }
}

function refreshAccount() {
  store.getAllowedAccounts(true)
}
</script>

<template>
  <NNotificationProvider />
  <UIContentWrap :locale="$i18n.locale" :fullScreen="true">
    <UISpin
      v-if="store.appLoading"
      size="large"
      class="absolute left-1/2 top-1/2"
    ></UISpin>
    <router-view v-else></router-view>
    <AddAccount :modalShow="showAddAccount" @close="closeAddAccount" />
    <TiktokModal
      :show-modal="showTiktokModal"
      @close="showTiktokModal = false"
    />
    <ConnectInstagramAccountInfo
      :show-modal="showConnectInstagramInfoModal"
      @close="showConnectInstagramInfoModal = false"
    />
    <ConnectCommunityAccount
      :show-modal="showConnectCommunityModal"
      @close="showConnectCommunityModal = false"
    />
    <ConnectFacebookAccount
      :show-modal="showConnectFacebookModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectFacebookModal = false"
      @refresh="refreshAccount"
    />
    <ConnectInstagramAccount
      :show-modal="showConnectInstagramModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectInstagramModal = false"
      @refresh="refreshAccount"
    />
    <ConnectTwitterAccount
      :show-modal="showConnectTwitterModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectTwitterModal = false"
      @refresh="refreshAccount"
    />
    <ConnectTiktokAccount
      :show-modal="showConnectTiktokModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectTiktokModal = false"
      @refresh="refreshAccount"
      :accountType="tiktokAccountType"
    />
    <ConnectLinkedinAccount
      :show-modal="showConnectLinkedinModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectLinkedinModal = false"
      @refresh="refreshAccount"
    />
    <ConnectGmbLocation
      :show-modal="showConnectGmbModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectGmbModal = false"
      @refresh="refreshAccount"
    />
    <ConnectYoutubeAccount
      :show-modal="showConnectYoutubeModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectYoutubeModal = false"
      @refresh="refreshAccount"
      :error="accountError"
    />
    <ConnectPinterestAccount
      :show-modal="showConnectPinterestModal"
      :oauth-id="oAuthId"
      :reconnectFilter="reconnectFilter"
      :reconnectAccounts="reconnectAccounts"
      @close="showConnectPinterestModal = false"
      @refresh="refreshAccount"
    />
  </UIContentWrap>
</template>

<style lang="scss">
#app {
  @apply bg-gray-100;
  font-family: Avenir, Helvetica, Arial, sans-serif, Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.hl_text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.teritary {
  @apply text-white hover:text-white rounded-md h-full bg-dodger-blue-500 hover:bg-dodger-blue-500;
}
.text-line-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}
#tooltip {
  @apply z-10;
}
.n-notification-container {
  top: 100px !important;
}
.n-notification {
  background-color: white !important;
}
</style>
