import AddSocialAccountModal from '../module/ptPT.add_social_account_modal.locale.json'
import CommonLocale from '../module/ptPT.common.locale.json'
import ConnectModal from '../module/ptPT.connect_modal.locale.json'
import CreatePost from '../module/ptPT.create_post.locale.json'
import CreateReviewPost from '../module/ptPT.create_review_post.locale.json'
import CreateRssPost from '../module/ptPT.create_rss_post.locale.json'
import CsvPost from '../module/ptPT.csv_post.locale.json'
import CustomValues from '../module/ptPT.custom_values.locale.json'
import Dashboard from '../module/ptPT.dashboard.locale.json'
import EditEmailTemplate from '../module/ptPT.edit_email_template.locale.json'
import Editor from '../module/ptPT.editor.locale.json'
import EmojiPicker from '../module/ptPT.emoji_picker.locale.json'
import InstagramModal from '../module/ptPT.instagram_modal.locale.json'
import InstagramStoryOptions from '../module/ptPT.instagram_story_options.locale.json'
import ListPost from '../module/ptPT.list_post.locale.json'
import LocationTagging from '../module/ptPT.location_tagging.locale.json'
import Pinterest from '../module/pinterest/ptBR.pinterest.locale.json'
import Planner from '../module/ptPT.planner.locale.json'
import PostPreview from '../module/ptPT.post_preview.locale.json'
import RecurringPostFormRules from '../module/ptPT.recurring_post_form_rules.locale.json'
import ScheduleRecurringModal from '../module/ptPT.schedule_recurring_modal.locale.json'
import SetEmailTemplate from '../module/ptPT.set_email_template.locale.json'
import Settings from '../module/ptPT.settings.locale.json'
import SocialAccountsDropDownLocale from '../module/ptPT.social_accounts_dropdown.locale.json'
import Statistics from '../module/ptPT.statistics.locale.json'
import TagsAndCategory from '../module/ptPT.tags_and_category.locale.json'
import TikTokOption from '../module/ptPT.tiktok_option.locale.json'
import UserSelect from '../module/ptPT.user_select.locale.json'
import VideoPreview from '../module/ptPT.video_preview.locale.json'
import Watermark from '../module/ptPT.watermark.locale.json'
import WeekDays from '../module/ptPT.week_days.locale.json'
import Welcome from '../module/ptPT.welcome.locale.json'
import Youtube from '../module/ptPT.youtube.locale.json'
import addGroupModal from '../module/ptPT.add_group_modal.locale.json'

export default {
  'pt-PT': {
    ...CommonLocale,
    ...SocialAccountsDropDownLocale,
    ...addGroupModal,
    ...AddSocialAccountModal,
    ...ConnectModal,
    ...CreatePost,
    ...CsvPost,
    ...CustomValues,
    ...EditEmailTemplate,
    ...EmojiPicker,
    ...InstagramModal,
    ...InstagramStoryOptions,
    ...Planner,
    ...PostPreview,
    ...SetEmailTemplate,
    ...Settings,
    ...UserSelect,
    ...VideoPreview,
    ...WeekDays,
    ...Welcome,
    ...Dashboard,
    ...ScheduleRecurringModal,
    ...ListPost,
    ...RecurringPostFormRules,
    ...Editor,
    ...TagsAndCategory,
    ...CreateRssPost,
    ...CreateReviewPost,
    ...TagsAndCategory,
    ...Watermark,
    ...TikTokOption,
    ...Youtube,
    ...Pinterest,
    ...LocationTagging,
    ...Statistics,
  },
}
