interface SetupPendo {
  companyId: string
  locationId: string
  userId: string
}

declare global {
  interface Window {
    pendo: any
  }
}

export function setupPendo(params: SetupPendo) {
  if (params.companyId) {
    let initOptions = {
      visitor: {
        id: params.userId,
      },
      account: {
        id: `${params.companyId}::${params.locationId}`,
      },
    }
    window.pendo && window.pendo.initialize(initOptions)
  }
}