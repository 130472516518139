export const aspectRatioValue = (width: number, height: number) => {
  if (!width || !height) {
    return 0
  }
  const { w, h } = ratioValues(width, height)
  return parseFloat(Number(w / h).toFixed(2))
}

export const aspectRatioString = (
  width: number,
  height: number,
  type: string
) => {
  if (!width || !height) {
    return `${width} : ${height}`
  }
  const { w, h } = ratioValues(width, height)
  if (type === 'image') return `${w} : ${h}`

  return `${w} / ${h}`
}

function ratioValues(width: number, height: number) {
  const divider = gcd(width, height)
  const w = width / divider
  const h = height / divider
  return { w, h }
}

function gcd(width: number, height: number): number {
  return height == 0 ? width : gcd(height, width % height)
}
