import { IMedia } from '../../../../types/media'
import { VideoBase } from './base'

export class Google extends VideoBase {
  constructor(media: IMedia) {
    super(media)
    this.typeError()
  }

  protected typeError(): void {
    // do nothing
  }

  protected durationError(): void {
    // do nothing
  }

  protected sizeError(): void {
    // do nothing
  }

  protected resolutionError(): void {
    // Do nothing
  }

  protected audioCodecNameError(): void {
    // Do nothing
  }

  protected audioChannelError(): void {
    // Do nothing
  }

  protected videoCodecError(): void {
    // Do nothing
  }

  protected frameRateError(): void {
    // Do nothing
  }

  protected aspectRatioError(): void {
    // Do nothing
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    // Do nothing
  }

  protected videoBitrateError(): void {
    // Do nothing
  }
}
