import config from '../config'
import { requests } from '.'

export const DefaultService = {
  allowedAccount: (id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/${id}/accounts`)
      .then(response => ({ ...response, data: response.data.results })),
  postMessage: (id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true).post(`/${id}/posts`, body),
  statPosts: (
    locationId: string,
    accounts?: any,
    startDate = '',
    endDate = ''
  ) =>
    requests(config.socialMediaServiceUrl)
      .post(`/${locationId}/stats`, {
        accounts,
        startDate,
        endDate,
      })
      .then(response => ({ ...response, data: response.data.results })),
  listPosts: (locationId: string, body: any) =>
    requests(config.socialMediaServiceUrl, true)
      .post(`/${locationId}/posts/list`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchDraft: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/${locationId}/posts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  saveDraft: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true).put(
      `/${locationId}/posts/${id}`,
      body
    ),
  setGroupPages: (accountId: string, body: any) =>
    requests(config.socialMediaServiceUrl).post(
      `/oauth/facebook/group-pages/${accountId}`,
      body
    ),
  removeAccount: (locationId: string, id: string, userId: string) =>
    requests(config.socialMediaServiceUrl, true).delete(
      `/${locationId}/accounts/${id}?userId=${userId}`
    ),
  deletePosts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .delete(`/${locationId}/posts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  metatags: (locationId: string, url: string) =>
    requests(config.socialMediaServiceUrl)
      .get(`/${locationId}/metatags?url=${url}`)
      .then(response => ({ ...response, data: response.data.results })),
  validateMedia: (url: string) =>
    requests(config.socialMediaServiceUrl).post(`/media`, { url: url }),
  createAccountGroup: (locationId: string, body: any) =>
    requests(config.socialMediaServiceUrl)
      .post(`/${locationId}/group/`, body)
      .then(response => ({ ...response, data: response.data.results })),
  deleteAccountGroup: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl)
      .delete(`/${locationId}/group/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  editAccountGroup: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl)
      .put(`/${locationId}/group/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  filters: () => requests(config.socialMediaServiceUrl).get(`/filters`),
  searchPosts: (
    locationId: string,
    body: {
      fromDate: string
      toDate: string
      type?: string[]
      status?: string[]
      approvalStatus?: string[]
      source?: string[]
      approver?: string[]
      createdBy?: string[]
      tags?: string[]
      categoryIds?: string[]
    }
  ) =>
    requests(config.socialMediaServiceUrl)
      .post(`/${locationId}/search`, body)
      .then(response => ({ ...response, data: response.data.results })),
}
