import AddSocialAccountModal from '../module/nl.add_social_account_modal.locale.json'
import CommonLocale from '../module/nl.common.locale.json'
import ConnectModal from '../module/nl.connect_modal.locale.json'
import CreatePost from '../module/nl.create_post.locale.json'
import CreateReviewPost from '../module/nl.create_review_post.locale.json'
import CreateRssPost from '../module/nl.create_rss_post.locale.json'
import CsvPost from '../module/nl.csv_post.locale.json'
import CustomValues from '../module/nl.custom_values.locale.json'
import Dashboard from '../module/nl.dashboard.locale.json'
import EditEmailTemplate from '../module/nl.edit_email_template.locale.json'
import Editor from '../module/nl.editor.locale.json'
import EmojiPicker from '../module/nl.emoji_picker.locale.json'
import InstagramModal from '../module/nl.instagram_modal.locale.json'
import InstagramStoryOptions from '../module/nl.instagram_story_options.locale.json'
import ListPost from '../module/nl.list_post.locale.json'
import LocationTagging from '../module/nl.location_tagging.locale.json'
import Pinterest from '../module/pinterest/nl.pinterest.locale.json'
import Planner from '../module/nl.planner.locale.json'
import PostPreview from '../module/nl.post_preview.locale.json'
import RecurringPostFormRules from '../module/nl.recurring_post_form_rules.locale.json'
import ScheduleRecurringModal from '../module/nl.schedule_recurring_modal.locale.json'
import SetEmailTemplate from '../module/nl.set_email_template.locale.json'
import Settings from '../module/nl.settings.locale.json'
import SocialAccountsDropDownLocale from '../module/nl.social_accounts_dropdown.locale.json'
import Statistics from '../module/nl.statistics.locale.json'
import TagsAndCategory from '../module/nl.tags_and_category.locale.json'
import TikTokOption from '../module/nl.tiktok_option.locale.json'
import UserSelect from '../module/nl.user_select.locale.json'
import VideoPreview from '../module/nl.video_preview.locale.json'
import Watermark from '../module/nl.watermark.locale.json'
import WeekDays from '../module/nl.week_days.locale.json'
import Welcome from '../module/nl.welcome.locale.json'
import Youtube from '../module/nl.youtube.locale.json'
import addGroupModal from '../module/nl.add_group_modal.locale.json'

export default {
  nl: {
    ...CommonLocale,
    ...SocialAccountsDropDownLocale,
    ...addGroupModal,
    ...AddSocialAccountModal,
    ...ConnectModal,
    ...CreatePost,
    ...CsvPost,
    ...CustomValues,
    ...EditEmailTemplate,
    ...EmojiPicker,
    ...InstagramModal,
    ...InstagramStoryOptions,
    ...Planner,
    ...PostPreview,
    ...SetEmailTemplate,
    ...Settings,
    ...UserSelect,
    ...VideoPreview,
    ...WeekDays,
    ...Welcome,
    ...Dashboard,
    ...ScheduleRecurringModal,
    ...ListPost,
    ...RecurringPostFormRules,
    ...Editor,
    ...TagsAndCategory,
    ...CreateRssPost,
    ...CreateReviewPost,
    ...TagsAndCategory,
    ...Watermark,
    ...TikTokOption,
    ...Youtube,
    ...Pinterest,
    ...LocationTagging,
    ...Statistics,
  },
}
