import { i18n } from '../../../../locales'
import { IMedia, MediaErrors } from '../../../../types/media'
import { VideoBase } from './base'

export class InstagramPost extends VideoBase {
  /**
   * Max video size supported 100 MB
   */
  readonly maxSize = 100 * 1000 * 1000

  /**
   * Max supported duration in seconds
   */
  readonly maxDuration = 60

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 3

  /**
   * Supported video format
   */
  readonly supportedFormat = ['video/mov', 'video/mp4']

  /**
   * Max aspect ratio support
   */
  readonly maxAspectRatio = 1.78

  /**
   * Min aspect ratio support
   */
  readonly minAspectRatio = 0.8

  /**
   * Max frame rate supported
   */
  readonly maxFrameRate = 60

  /**
   * Min frame rate supported
   */
  readonly minFrameRate = 23

  /**
   * Supported Audio Codec
   */
  readonly supportedAudioCodec = ['aac']

  /**
   * Supported Video Codec
   */
  readonly supportedVideoCodec = ['h264', 'hevc']

  /**
   * Max width
   */
  readonly maxWidth = 1920

  /**
   * Supported Audio Channel
   */
  readonly supportedAudioChannel = [1, 2]

  /**
   * Max Audio Bitrate
   */
  readonly maxAudioBitrate = 128 * 1000

  /**
   * Max Video Bitrate
   */
  readonly maxVideoBitrate = 25 * 1000 * 1000

  /**
   * Platform name to be display
   */
  readonly platform = 'Instagram Professional'

  constructor(media: IMedia) {
    super(media)
    this.typeError()
    this.durationError()
    this.sizeError()
    this.resolutionError()
    this.audioCodecNameError()
    this.audioChannelError()
    this.videoCodecError()
    this.frameRateError()
    this.aspectRatioError()
    // this.audioBitrateError()
    // this.videoBitrateError()
  }

  protected typeError(): void {
    if (!this.supportedFormat.includes(this.video.type || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.format', {
          platform: this.platform,
          format: this.getVideoExtension(),
        }),
        invalidType: MediaErrors.VIDEO_TYPE,
      })
    }
  }

  protected durationError(): void {
    if (
      !this.video.duration ||
      this.video.duration > this.maxDuration ||
      this.video.duration < this.minDuration
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.instagramDuration', {
          platform: this.platform,
          duration: this.video.displayDuration,
        }),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected sizeError(): void {
    if (!this.video.size || this.video.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.instagramSize', {
          platform: this.platform,
          size: this.video.size ? (this.video.size / 1000000).toFixed(2) : '0',
        }),
        invalidType: MediaErrors.VIDEO_SIZE,
      })
    }
  }

  protected resolutionError(): void {
    if (
      !(
        this.video.width &&
        this.video.width <= this.maxWidth &&
        this.video.width > 0
      ) ||
      !(this.video.height && this.video.height > 0)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.instagramVideoWidth', {
          platform: this.platform,
          width: this.video.width,
        }),
        invalidType: MediaErrors.VIDEO_WIDTH_HEIGHT,
      })
    }
  }

  protected audioCodecNameError(): void {
    if (
      this.video.audioCodecName != undefined &&
      !this.supportedAudioCodec.includes(this.video.audioCodecName)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.audioCodecName', {
          platform: this.platform,
          audioCodecName: this.video.audioCodecName,
        }),
        invalidType: MediaErrors.VIDEO_AUDIO_CODEC,
      })
    }
  }

  protected audioChannelError(): void {
    if (
      this.video.audioChannels != undefined &&
      !this.supportedAudioChannel.includes(this.video.audioChannels)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.audioCodecChannel', {
          platform: this.platform,
          audioCodecChannels: this.video.audioChannels,
        }),
        invalidType: MediaErrors.VIDEO_AUDIO_CHANNEL,
      })
    }
  }

  protected videoCodecError(): void {
    if (!this.supportedVideoCodec.includes(this.video.videoCodecName || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.videoCodecName', {
          platform: this.platform,
          videoCodecName: this.video.videoCodecName,
        }),
        invalidType: MediaErrors.VIDEO_CODEC,
      })
    }
  }

  protected frameRateError(): void {
    if (
      !this.video.frameRate ||
      this.video.frameRate > this.maxFrameRate ||
      this.video.frameRate < this.minFrameRate
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.instagramFrameRate', {
          platform: this.platform,
          frameRate: this.video.frameRate,
        }),
        invalidType: MediaErrors.VIDEO_FRAME_RATE,
      })
    }
  }

  protected aspectRatioError(): void {
    if (
      !this.video.aspectRatio ||
      +this.video.aspectRatio > this.maxAspectRatio ||
      +this.video.aspectRatio < this.minAspectRatio
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.instagramAspectRatio', {
          platform: this.platform,
          aspectRatio: this.video.displayAspectRatio,
        }),
        invalidType: MediaErrors.VIDEO_ASPECT_RATIO,
      })
    }
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    if (
      this.video.audioBitrate &&
      this.video.audioBitrate > this.maxAudioBitrate
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.instagramAudioBitrate',
          {
            platform: this.platform,
            audioBitrate: this.video.audioBitrate
              ? (this.video.audioBitrate / 1000).toFixed(2)
              : '0',
          }
        ),
        invalidType: MediaErrors.AUDIO_BITRATE,
      })
    }
  }

  protected videoBitrateError(): void {
    if (
      this.video.videoBitrate &&
      this.video.videoBitrate > this.maxVideoBitrate
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.instagramVideoBitrate',
          {
            platform: this.platform,
            videoBitrate: this.video.videoBitrate
              ? (this.video.videoBitrate / 1000000).toFixed(2)
              : '0',
          }
        ),
        invalidType: MediaErrors.VIDEO_BITRATE,
      })
    }
  }
}
