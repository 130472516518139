import { ErrorMessage, ErrorResponse, IMedia, MediaErrors } from '@/types/media'

import { i18n } from '../../../../locales'

export abstract class ImageBase {
  /**
   * Image object with image details
   */
  image: IMedia = {}

  /**
   * Thumbnail image max size supported by our platform
   */
  readonly thumbnailMaxSize = 10240000

  /**
   * Minimum width and height required for any image except thumbnail
   */
  readonly minWidthHight = 250

  /**
   * Minimum image size required for any image except thumbnail
   */
  readonly minSize = 10240

  /**
   * Is image uploaded for custom thumbnail or video cover image
   */
  isThumbnail = false

  /**
   * Flag will set when image is invalid
   */
  isInvalid = false

  /**
   * Errors will hold all the error type
   */
  errors: ErrorMessage[] = []

  /**
   * Base errors which applicable for all social platform
   * @param media
   * @param isThumbnail
   * @param baseErrors
   */
  baseErrors: ErrorMessage[] = []

  constructor(media: IMedia, isThumbnail: boolean, baseErrors: boolean) {
    this.image = media
    this.isThumbnail = isThumbnail

    if (baseErrors) {
      this.minSizeError()
      this.resolutionError()

      if (this.isThumbnail) {
        this.maxSizeError()
        this.typeError()
      }
    }
  }

  /**
   * Get first error message from array
   * @returns ErrorResponse
   */
  getError(): ErrorResponse {
    return this.isInvalid
      ? { invalid: this.isInvalid, error: this.errors[0] }
      : undefined
  }

  /**
   * Get base error message from array
   * @returns ErrorResponse
   */
  getBaseError(): ErrorResponse {
    return this.baseErrors.length ? this.baseErrors[0] : undefined
  }

  getImageExtension(): string {
    return this.image.type.split('/')[1]
  }

  /**
   * Check min image size error
   */
  protected minSizeError() {
    if (
      !this.isThumbnail &&
      this.image.size &&
      this.image.size < this.minSize
    ) {
      this.baseErrors.push({
        invalid: true,
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidImage.minSize', {
          size: (this.image.size / 1000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  /**
   * Check max image size error
   */
  protected maxSizeError() {
    if (
      this.isThumbnail &&
      this.image.size &&
      this.image.size > this.thumbnailMaxSize
    ) {
      this.baseErrors.push({
        invalid: true,
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidVideo.coverSize', {
          size: (this.image.size / (1000000 * 1000)).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  /**
   * check image type error
   */
  protected typeError() {
    if (
      this.isThumbnail &&
      this.image.type &&
      this.image.type === 'image/gif'
    ) {
      this.baseErrors.push({
        invalid: true,
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidVideo.coverFormat', {
          format: this.getImageExtension(),
        }),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }

  /**
   * Check width and height error
   */
  protected resolutionError() {
    if (
      !this.isThumbnail &&
      (this.image.width < 250 || this.image.height < 250)
    ) {
      this.baseErrors.push({
        invalid: true,
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidImage.dimensions', {
          width: this.image.width,
          height: this.image.height,
        }),
        invalidType: MediaErrors.IMAGE_WIDTH,
      })
    }
  }

  /**
   * Get the error related to aspect ratio
   */
  protected abstract aspectRatioError(): void
}
