import { IMedia, MediaErrors } from '@/types/media'
import { i18n } from '../../../../locales'
import { ImageBase } from './base'

export class Twitter extends ImageBase {
  /**
   * Max image size supported 5 MB
   */
  readonly maxSize = 5000000

  /**
   * Platform name
   */
  readonly platform = 'Twitter(X)'

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
    this.maxSizeError()
  }

  protected maxSizeError(): void {
    if (this.image.size && this.image.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidImage.size', {
          platform: this.platform,
          limit: '5MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  protected aspectRatioError(): void {
    // Do nothing
  }
}
