import { IMedia } from '../../../../types/media'
import { Community } from './community'
import { FacebookPost } from './facebookPost'
import { FacebookReel } from './facebookReel'
import { Google } from './google'
import { InstagramPost } from './instagramPost'
import { InstagramReel } from './instagramReel'
import { InstagramStory } from './instagramStory'
import { Linkedin } from './linkedin'
import { Pinterest } from './pinterest'
import { TikTok } from './tiktok'
import { TikTokBusiness } from './tiktokBusiness'
import { Twitter } from './twitter'
import { YoutubeShorts } from './youtubeShorts'
import { YoutubeVideos } from './youtubeVideos'
import { YoutubeVideosVerified } from './youtubeVideosVerified'

export class VideoValidator {
  /**
   * Facebook Post Video error handler
   */
  facebookPost: FacebookPost

  /**
   * Facebook Reel Video error handler
   */
  facebookReel: FacebookReel

  /**
   * Linkedin video error handler
   */
  linkedin: Linkedin

  /**
   * Linkedin video error handler
   */
  twitter: Twitter

  /**
   * Instagram Professional post video error handler
   */
  instagramPost: InstagramPost

  /**
   * Instagram Story video error handler
   */
  instagramStory: InstagramStory

  /**
   * Instagram Reel video error handler
   */
  instagramReel: InstagramReel

  /**
   * Google Video error handler
   */
  google: Google

  /**
   * TikTok video error handler
   */
  tikTok: TikTok

  /**
   * Youtube Videos video error handler
   */
  youtubeVideos: YoutubeVideos

  /**
   * Youtube Shorts video error handler
   */
  youtubeShorts: YoutubeShorts

  /**
   * Youtube Shorts video error handler
   */
  youtubeVideosVerified: YoutubeVideosVerified

  /**
   * TikTok Business video error handler
   */
  tikTokBusiness: TikTokBusiness

  /**
   * Pinterest video error handler
   */
  pinterest: Pinterest

  /**
   * Community video error handler
   */
  community: Community

  constructor(media: IMedia) {
    this.facebookPost = new FacebookPost(media)
    this.facebookReel = new FacebookReel(media)
    this.linkedin = new Linkedin(media)
    this.twitter = new Twitter(media)
    this.instagramPost = new InstagramPost(media)
    this.instagramStory = new InstagramStory(media)
    this.instagramReel = new InstagramReel(media)
    this.google = new Google(media)
    this.tikTok = new TikTok(media)
    this.youtubeVideos = new YoutubeVideos(media)
    this.youtubeShorts = new YoutubeShorts(media)
    this.youtubeVideosVerified = new YoutubeVideosVerified(media)
    this.tikTokBusiness = new TikTokBusiness(media)
    this.pinterest = new Pinterest(media)
    this.community = new Community(media)
  }

  getError() {
    return {
      facebook: {
        post: this.facebookPost.getError(),
        reel: this.facebookReel.getError(),
      },
      instagram: {
        post: this.instagramPost.getError(),
        story: this.instagramStory.getError(),
        reel: this.instagramReel.getError(),
      },
      linkedin: this.linkedin.getError(),
      twitter: this.twitter.getError(),
      google: this.google.getError(),
      tiktok: this.tikTok.getError(),
      youtube: {
        video: this.youtubeVideos.getError(),
        short: this.youtubeShorts.getError(),
        verified: this.youtubeVideosVerified.getError(),
      },
      tikTokBusiness: this.tikTokBusiness.getError(),
      pinterest: this.pinterest.getError(),
      community: this.community.getError(),
    }
  }
}
