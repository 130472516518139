import { MediaValidator } from '../components/post/validator/mediaValidator'
import { DefaultService } from '../service'
import { IMedia } from '../types/media'
import { aspectRatioString, aspectRatioValue } from './useAspectRatio'

interface IValidateVideo {
  id?: string
  videoCodecName?: string
  height?: number
  width?: number
  duration?: number
  size?: number
  format?: string
  audioCodecName?: string
  audioChannels?: number
  frameRate: number
  aspectRatio: number
  type: string
}

function getVideoDimensionsOf(url: string): Promise<IValidateVideo> {
  return new Promise(resolve => {
    DefaultService.validateMedia(url).then(({ data }) => {
      resolve(data)
    })
  })
}

export const useVideoHelper = async (
  media: { url: string; type: string },
  requestId?: string,
  skipVideoDownload?: boolean
): Promise<IMedia> => {
  try {
    let data: IValidateVideo | { url: string; type: string } = media
    if (!skipVideoDownload) {
      data = await getVideoDimensionsOf(media.url)
      if (data.type && data.type.includes('quicktime')) {
        data.type = 'video/mov'
      }
    }
    const video: IMedia = {
      ...media,
      ...data,
      invalid: false,
      requestId,
      displayDuration: '',
    }
    if (video.duration && !isNaN(video.duration as any)) {
      const date = new Date(null)
      date.setSeconds(video.duration as number)
      video.displayDuration = date.toISOString().substr(11, 8)
    }

    if (video.width && video.height) {
      video.aspectRatio = aspectRatioValue(video.width, video.height)
      video.displayAspectRatio = aspectRatioString(
        video.width,
        video.height,
        'video'
      )
    }
    if (video.frameRate) {
      video.frameRate = Math.round(video.frameRate)
    }
    return Promise.resolve({
      ...new MediaValidator(video, false).getError(),
    })
  } catch (err) {
    return Promise.resolve({
      ...media,
      invalid: true,
    })
  }
}
