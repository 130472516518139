import { IMedia, MediaErrors } from '@/types/media'

import { i18n } from '../../../../locales'
import { ImageBase } from './base'

export class Google extends ImageBase {
  /**
   * Max image size supported 10 MB
   */
  readonly maxSize = 5240000

  /**
   * Platform Name
   */
  readonly platform = 'Google My Business'

  /**
   * supported image formats
   */
  readonly imageFormat = ['image/jpeg', 'image/jpg', 'image/png']

  /**
   *
   * Minimum dimension
   */
  readonly minDimension = 720

  /**
   *
   * Maximum dimension
   */
  readonly maxDimension = 3000

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
    this.typeError()
    this.maxSizeError()
    this.resolutionError()
  }

  protected maxSizeError(): void {
    if (this.image.size && this.image.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidImage.size', {
          platform: this.platform,
          limit: '5MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  protected typeError(): void {
    if (this.image.type && !this.imageFormat.includes(this.image.type)) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.formatTitle'),
        message: i18n.global.t('createPost.invalidImage.onlyJpegPngFormat', {
          platform: this.platform,
          format: this.getImageExtension(),
        }),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }

  protected resolutionError() {
    if (
      this.image.width < this.minDimension ||
      this.image.height < this.minDimension ||
      this.image.width > this.maxDimension ||
      this.image.height > this.maxDimension
    ) {
      this.isInvalid = true
      this.errors.push({
        invalid: true,
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidImage.platformDimensions', {
          platform: this.platform,
          minDimension: `${this.minDimension}X${this.minDimension}`,
          maxDimension: `${this.maxDimension}X${this.maxDimension}`,
          width: this.image.width,
          height: this.image.height,
        }),
        invalidType: MediaErrors.IMAGE_DIMENSION,
      })
    }
  }

  protected aspectRatioError(): void {
    // Do nothing
  }
}
