import { IMedia, MediaErrors } from '@/types/media'
import { i18n } from '../../../../locales'
import { VideoBase } from './base'

export class YoutubeVideos extends VideoBase {
  /**
   * Max supported duration in seconds
   */
  readonly maxDuration = 15 * 60

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 1

  /**
   * Max frame rate supported
   */
  readonly maxWidth = 7680

  /**
   * Min frame rate supported
   */
  readonly minWidth = 240

  /**
   * Platform name to be display
   */

  readonly platform = 'Youtube'

  constructor(media: IMedia) {
    super(media)
    this.durationError()
    this.resolutionError()
    this.aspectRatioError()
  }

  protected durationError(): void {
    if (
      !this.video.duration ||
      this.video.duration > this.maxDuration ||
      this.video.duration < this.minDuration
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'youtube.invalidVideo.youtubeUnverifiedVideoDuration',
          {
            duration: this.video.displayDuration,
          }
        ),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected resolutionError(): void {
    if (
      !(
        this.video.width &&
        this.video.width <= this.maxWidth &&
        this.video.width >= this.minWidth
      )
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('youtube.invalidVideo.youtubeVideoWidth', {
          height: this.video.height,
          width: this.video.width,
        }),
        invalidType: MediaErrors.VIDEO_WIDTH_HEIGHT,
      })
    }
  }

  protected typeError(): void {
    // do nothing
  }

  protected sizeError(): void {
    // do nothing
  }

  protected audioCodecNameError(): void {
    // Do nothing
  }

  protected audioChannelError(): void {
    // Do nothing
  }

  protected videoCodecError(): void {
    // Do nothing
  }

  protected frameRateError(): void {
    // Do nothing
  }

  protected aspectRatioError(): void {
    // Do nothing
  }
}
