import { requests } from '.'
import config from '../config'

export const CommunityService = {
  fetchUsersByGroupAndChannel: (
    locationId: string,
    accountId: string,
    searchText = ''
  ) =>
    requests(config.socialMediaServiceUrl)
      .get(
        `/${locationId}/community/accounts/${accountId}/users?searchText=${searchText}`
      )
      .then(response => ({ ...response, data: response.data.results })),
  startCommunityOAuth: (locationId: string) =>
    requests(config.socialMediaServiceUrl)
      .get(`/oauth/community/start?locationId=${locationId}`)
      .then(response => ({ ...response, data: response.data.results })),
  finishCommunityOAuth: (locationId: string, userId: string) =>
    requests(config.socialMediaServiceUrl)
      .get(`/oauth/community/finish?locationId=${locationId}&userId=${userId}`)
      .then(response => ({ ...response, data: response.data.results })),
  fetchCommunityAccounts: (locationId: string) =>
    requests(config.socialMediaServiceUrl)
      .get(`/${locationId}/community/accounts`)
      .then(response => ({ ...response, data: response.data.results })),
  addCommunityAccount: (locationId: string, body: any) =>
    requests(config.socialMediaServiceUrl)
      .post(`/${locationId}/community/accounts`, body)
      .then(response => ({ ...response, data: response.data.results })),
}
