import { IMedia } from '../../../types/media'
import { ImageValidator } from './image'
import { VideoValidator } from './video'

export class MediaValidator {
  /**
   * Selected Media
   */
  media: IMedia

  /**
   * Image or Video Validator
   */
  validator: ImageValidator | VideoValidator

  constructor(media: IMedia, isThumbnail: boolean) {
    this.media = media
    if (this.media.type && this.media.type.includes('image')) {
      this.validator = new ImageValidator(this.media, isThumbnail)
    } else {
      this.validator = new VideoValidator(this.media)
    }
  }

  getError() {
    return {
      ...this.media,
      ...this.validator.getError(),
    }
  }
}
