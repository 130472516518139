import { ErrorMessage, ErrorResponse, IMedia } from '../../../../types/media'

export abstract class VideoBase {
  /**
   * Video object with details
   */
  video: IMedia

  /**
   * Flag will set when image is invalid
   */
  isInvalid = false

  /**
   * Errors will hold all the error type
   */
  errors: ErrorMessage[] = []

  constructor(media: IMedia) {
    this.video = media
  }

  /**
   * Get first error message from array
   * @returns ErrorResponse
   */
  getError(): ErrorResponse | undefined {
    return this.isInvalid
      ? { invalid: this.isInvalid, error: this.errors[0] }
      : undefined
  }

  getVideoExtension(): string {
    return this.video.type ? this.video.type.split('/')[1] : ''
  }

  /**
   * Check video supported type error
   */
  protected abstract typeError(): void

  /**
   * Check video duration error
   */
  protected abstract durationError(): void

  /**
   * Check video size error
   */
  protected abstract sizeError(): void

  /**
   * Check video resolution error
   */
  protected abstract resolutionError(): void

  /**
   * Check video audio codec name error
   */
  protected abstract audioCodecNameError(): void

  /**
   * Check video audio channel error
   */
  protected abstract audioChannelError(): void

  /**
   * Check video codec error
   */
  protected abstract videoCodecError(): void

  /**
   * Check video frame rate error
   */
  protected abstract frameRateError(): void

  /**
   * Get the error related to aspect ratio
   */
  protected abstract aspectRatioError(): void

  /**
   * Check audio sample rate error
   */
  protected abstract audioSampleRateError(): void

  /**
   * Check audio bitrate error
   */
  protected abstract audioBitrateError(): void

  /**
   * Check video bitrate error
   */
  protected abstract videoBitrateError(): void
}
