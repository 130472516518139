import AddSocialAccountModal from '../module/fi.add_social_account_modal.locale.json'
import CommonLocale from '../module/fi.common.locale.json'
import ConnectModal from '../module/fi.connect_modal.locale.json'
import CreatePost from '../module/fi.create_post.locale.json'
import CreateReviewPost from '../module/fi.create_review_post.locale.json'
import CreateRssPost from '../module/fi.create_rss_post.locale.json'
import CsvPost from '../module/fi.csv_post.locale.json'
import CustomValues from '../module/fi.custom_values.locale.json'
import Dashboard from '../module/fi.dashboard.locale.json'
import EditEmailTemplate from '../module/fi.edit_email_template.locale.json'
import Editor from '../module/fi.editor.locale.json'
import EmojiPicker from '../module/fi.emoji_picker.locale.json'
import InstagramModal from '../module/fi.instagram_modal.locale.json'
import InstagramStoryOptions from '../module/fi.instagram_story_options.locale.json'
import ListPost from '../module/fi.list_post.locale.json'
import LocationTagging from '../module/fi.location_tagging.locale.json'
import Pinterest from '../module/pinterest/fi.pinterest.locale.json'
import Planner from '../module/fi.planner.locale.json'
import PostPreview from '../module/fi.post_preview.locale.json'
import RecurringPostFormRules from '../module/fi.recurring_post_form_rules.locale.json'
import ScheduleRecurringModal from '../module/fi.schedule_recurring_modal.locale.json'
import SetEmailTemplate from '../module/fi.set_email_template.locale.json'
import Settings from '../module/fi.settings.locale.json'
import SocialAccountsDropDownLocale from '../module/fi.social_accounts_dropdown.locale.json'
import Statistics from '../module/fi.statistics.locale.json'
import TagsAndCategory from '../module/fi.tags_and_category.locale.json'
import TikTokOption from '../module/fi.tiktok_option.locale.json'
import UserSelect from '../module/fi.user_select.locale.json'
import VideoPreview from '../module/fi.video_preview.locale.json'
import Watermark from '../module/fi.watermark.locale.json'
import WeekDays from '../module/fi.week_days.locale.json'
import Welcome from '../module/fi.welcome.locale.json'
import Youtube from '../module/fi.youtube.locale.json'
import addGroupModal from '../module/fi.add_group_modal.locale.json'

export default {
  fi: {
    ...CommonLocale,
    ...SocialAccountsDropDownLocale,
    ...addGroupModal,
    ...AddSocialAccountModal,
    ...ConnectModal,
    ...CreatePost,
    ...CsvPost,
    ...CustomValues,
    ...EditEmailTemplate,
    ...EmojiPicker,
    ...InstagramModal,
    ...InstagramStoryOptions,
    ...Planner,
    ...PostPreview,
    ...SetEmailTemplate,
    ...Settings,
    ...UserSelect,
    ...VideoPreview,
    ...WeekDays,
    ...Welcome,
    ...Dashboard,
    ...ScheduleRecurringModal,
    ...ListPost,
    ...RecurringPostFormRules,
    ...Editor,
    ...TagsAndCategory,
    ...Watermark,
    ...CreateRssPost,
    ...CreateReviewPost,
    ...TikTokOption,
    ...Youtube,
    ...Pinterest,
    ...LocationTagging,
    ...Statistics,
  },
}
