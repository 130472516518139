import { i18n } from '../../../../locales'
import { IMedia, MediaErrors } from '../../../../types/media'
import { VideoBase } from './base'

export class FacebookReel extends VideoBase {
  /**
   * Max video size supported 1 GB
   */
  readonly maxSize = 1000 * 1000 * 1000

  /**
   * Max supported duration in seconds
   */
  readonly maxDuration = 90

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 3

  /**
   * Supported video format
   */
  readonly supportedFormat = ['video/mov', 'video/mp4']

  /**
   * aspect ratio support
   */
  readonly requiredAspectRatio = 0.56

  /**
   * Max frame rate supported
   */
  readonly maxFrameRate = 60

  /**
   * Min frame rate supported
   */
  readonly minFrameRate = 24

  /**
   * Supported Audio Codec
   */
  readonly supportedAudioCodec = ['aac']

  /**
   * Supported Video Codec
   */
  readonly supportedVideoCodec = ['h264', 'hevc', 'vp9', 'av1']

  /**
   * Minimum width
   */
  readonly minWidth = 540

  /**
   * Minimum height
   */
  readonly minHeight = 960

  /**
   * Supported Audio Channel
   */
  readonly supportedAudioChannel = [2]

  /**
   * Platform name to be display
   */
  readonly platform = 'Facebook Reel'

  constructor(media: IMedia) {
    super(media)
    this.durationError()
    this.sizeError()
    this.resolutionError()
    this.audioCodecNameError()
    this.audioChannelError()
    this.videoCodecError()
    this.frameRateError()
    this.aspectRatioError()
    this.audioSampleRateError()
    this.audioBitrateError()
    this.videoBitrateError()
  }

  protected typeError(): void {
    // Do nothing
  }

  protected durationError(): void {
    if (
      !this.video.duration ||
      this.video.duration > this.maxDuration ||
      this.video.duration < this.minDuration
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.facebookReelDuration', {
          platform: this.platform,
          duration: this.video.displayDuration,
        }),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected sizeError(): void {
    if (!this.video.size || this.video.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.facebookReelSize', {
          platform: this.platform,
          size: this.video.size ? (this.video.size / 1000000).toFixed(2) : '0',
        }),
        invalidType: MediaErrors.VIDEO_SIZE,
      })
    }
  }

  protected resolutionError(): void {
    if (
      !(this.video.width && this.video.width >= this.minWidth) ||
      !(this.video.height && this.video.height >= this.minHeight)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.facebookReelResolution',
          {
            platform: this.platform,
            width: this.video.width,
            height: this.video.height,
          }
        ),
        invalidType: MediaErrors.VIDEO_WIDTH_HEIGHT,
      })
    }
  }

  protected audioCodecNameError(): void {
    if (
      this.video.audioCodecName !== undefined &&
      !this.supportedAudioCodec.includes(this.video.audioCodecName)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.audioCodecName', {
          platform: this.platform,
          audioCodecName: this.video.audioCodecName,
        }),
        invalidType: MediaErrors.VIDEO_AUDIO_CODEC,
      })
    }
  }

  protected audioChannelError(): void {
    if (
      this.video.audioChannels !== undefined &&
      !this.supportedAudioChannel.includes(this.video.audioChannels)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.audioCodecChannel', {
          platform: this.platform,
          audioCodecChannels: this.video.audioChannels,
        }),
        invalidType: MediaErrors.VIDEO_AUDIO_CHANNEL,
      })
    }
  }

  protected videoCodecError(): void {
    if (!this.supportedVideoCodec.includes(this.video.videoCodecName || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.facebookReelVideoCodecName',
          {
            platform: this.platform,
            videoCodecName: this.video.videoCodecName,
          }
        ),
        invalidType: MediaErrors.VIDEO_CODEC,
      })
    }
  }

  protected frameRateError(): void {
    if (
      !this.video.frameRate ||
      this.video.frameRate > this.maxFrameRate ||
      this.video.frameRate < this.minFrameRate
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.facebookReelFrameRate',
          {
            platform: this.platform,
            frameRate: this.video.frameRate,
          }
        ),
        invalidType: MediaErrors.VIDEO_FRAME_RATE,
      })
    }
  }

  protected aspectRatioError(): void {
    if (
      !this.video.aspectRatio ||
      +this.video.aspectRatio !== this.requiredAspectRatio
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.facebookReelAspectRatio',
          {
            aspectRatio: this.video.displayAspectRatio,
          }
        ),
        invalidType: MediaErrors.VIDEO_ASPECT_RATIO,
      })
    }
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    // Do nothing
  }

  protected videoBitrateError(): void {
    // Do nothing
  }
}
