import { updateMediaForPlatforms } from '../compositions/useDocumentHelper'
import {
  Account,
  AccountType,
  CommunityPostDetails,
  Errors,
  Features,
  Group,
  ICustomValueOptions,
  LinkedinPostDetails,
  LocationUser,
  PinterestPostDetails,
  Platform,
  PlatformMention,
  PostPayload,
  PostSource,
  PostTabs,
  PostType,
  TokenData,
  UserPermission,
  YoutubePostDetails,
  YoutubePostType,
  YoutubePrivacyLevel,
} from '../types'

import config from '../config'
import { DefaultService } from '../service'
// https://pinia.esm.dev/introduction.html#basic-example
import { defineStore } from 'pinia'
import { i18n } from '../locales'
// https://pinia.esm.dev/introduction.html#basic-example
import moment from 'moment'
import { useImageHelper } from '../compositions/useImageHelper'
import { useHtmlFormat } from '../compositions/useTextFormat'
import { useVideoHelper } from '../compositions/useVideoHelper'
import { SearchResult } from '../types/mentions'
import { updateParent } from '../util/updateParent'

interface Store {
  appHandshake: number
  retryCount: number
  tokenId: string
  userId: string
  userType: string
  userRole: string
  companyId: string
  locationId: string
  initialised: boolean
  isFetchingAccounts: boolean
  accounts: Account[]
  groups: Group[]
  locationUsers: LocationUser[]
  accountId: string
  appLoading: boolean
  payload: PostPayload
  selectedLocations: string[]
  id: string | undefined
  errors: Errors
  editSchedulePost: boolean
  timeZone: string
  changeRoute: boolean
  ogTags?: any
  customValues: ICustomValueOptions[]
  postType: PostType
  userPermissions: UserPermission
  features: Features
  locale: string
  selectedPlatform: Platform[]
  isCustomizedPost: boolean
  defaultPlatform: Platform | undefined
  source: PostSource | undefined
  isGMBConnected: boolean
  isFbConnected: boolean
  companyStripePlan: string
  categoryId?: string
  tags?: string[]
  watermarkTemplateId?: string
  selectedAccountTypes: AccountType[]
  tiktokSelectedAccountTypes: AccountType[]
  previousDashboardTab?: PostTabs
  isUnverifiedYoutubeSelected: boolean
  isInstagramSelected: boolean
  authTokenV2: string
  isBetaTestingAgency?: boolean
}

const ACTION_TYPES = {
  EVENT: 'event',
  OFFER: 'offer',
  STANDARD: 'action',
} as any

export const useSocialMediaStore = defineStore('socialMediaPosting', {
  state: (): Store => {
    return {
      appHandshake: 0,
      retryCount: 0,
      tokenId: (import.meta.env.VITE_TOKEN_ID as string) || '',
      userId: (import.meta.env.VITE_USER_ID as string) || '',
      userType: (import.meta.env.VITE_USER_TYPE as string) || '',
      userRole: (import.meta.env.VITE_USER_ROLE as string) || '',
      companyId: (import.meta.env.VITE_COMPANY_ID as string) || '',
      locationId: (import.meta.env.VITE_LOCATION_ID as string) || '',
      initialised: (import.meta.env.VITE_INITIALISED as boolean) || false,
      isFetchingAccounts: false,
      accounts: [],
      groups: [],
      accountId: '',
      appLoading: true,
      payload: {
        actionType: 'action',
        facebook: {
          content: '',
          htmlContent: '',
        },
        instagram: {
          content: '',
          htmlContent: '',
        },
        google: {
          content: '',
          htmlContent: '',
        },
        linkedin: {
          content: '',
          htmlContent: '',
          postDetails: {
            postAsPdf: false,
            pdfTitle: '',
          },
        },
        twitter: {
          content: '',
          htmlContent: '',
        },
        tiktok: {
          content: '',
          htmlContent: '',
        },
        youtube: {
          content: '',
          htmlContent: '',
          postDetails: {
            privacyLevel: YoutubePrivacyLevel.PUBLIC,
            title: '',
            type: YoutubePostType.VIDEO,
          },
        },
        pinterest: {
          content: '',
          htmlContent: '',
          postDetails: {
            link: '',
            title: '',
            boardIds: {},
          },
        },
        community: {
          content: '',
          htmlContent: '',
          postDetails: {
            title: '',
            notifyAllGroupMembers: false,
            postAsUser: {},
          },
        },
        enableComment: false,
        enableDuet: false,
        enableStitch: false,
        videoDisclosure: false,
        promoteYourBrand: false,
        promoteOtherBrand: false,
        locationTags: [],
      },
      selectedLocations: [],
      id: '',
      errors: {},
      editSchedulePost: false,
      timeZone: 'UTC',
      changeRoute: false,
      ogTags: undefined,
      locationUsers: [],
      postType: PostType.POST,
      customValues: [],
      userPermissions: {
        contentAiEnabled: false,
      },
      features: {
        contentAI: false,
        templateLib: false,
        imageEditor: false,
        reviewPost: true,
      },
      locale: 'en-US',
      selectedPlatform: [],
      isCustomizedPost: false,
      defaultPlatform: undefined,
      source: undefined,
      isGMBConnected: false,
      isFbConnected: false,
      companyStripePlan: '',
      previousDashboardTab: PostTabs.POST,
      isUnverifiedYoutubeSelected: false,
      isInstagramSelected: false,
      authTokenV2: '',
      isBetaTestingAgency: true,
    }
  },
  actions: {
    async init(payload: any, handshake: any) {
      this.setAppInitData(payload)
      this.setAppHandshake(handshake)
      this.setAccounts(payload.accounts)
      this.setGroups(payload.groups)
      // await this.getAllowedAccounts()
      if (this.accounts.length) this.initialised = true
      this.setLoader(false)
      this.setEditSchedulePost(false)
      if (payload.locationUsers) this.setLocationUsers(payload.locationUsers)
      this.setLocales(payload.locale)
    },
    updateRetryCount(count: number) {
      this.retryCount = count
    },
    setAppHandshake(payload: any) {
      this.appHandshake = payload
    },
    setAppInitData(payload: any) {
      this.userId = payload.userId
      this.userRole = payload.userRole
      this.userType = payload.userType
      this.companyId = payload.companyId
      this.locationId = payload.locationId
      this.initialised = payload.initialised
      this.tokenId = payload.tokenId
      this.errors = {}
      this.userPermissions = payload.userPermissions
      this.features = payload.features
      if (payload.timezone) this.timeZone = payload.timezone
      this.companyStripePlan = payload.companyStripePlan
      this.isGMBConnected = payload.isGMBConnected
      this.isFbConnected = payload.isFbConnected
      this.previousDashboardTab = payload.previousDashboardTab
      this.authTokenV2 = payload.authTokenV2
      this.isBetaTestingAgency = true
    },
    refreshToken(newToken: TokenData) {
      this.tokenId = newToken.tokenId
      this.authTokenV2 = newToken.authTokenV2
    },
    initialisedPlanner(init: boolean) {
      this.initialised = init
    },
    async updateConnection(payload: any) {
      // this.setLoader(true)
      this.accountId = payload.accountId
      await this.getAllowedAccounts(true)
      // this.setLoader(false)
    },
    async getAllowedAccounts(isSilent = false, onlyGroup = false) {
      try {
        if (!isSilent) {
          this.isFetchingAccounts = true
          this.accounts = []
        }
        const { data } = await DefaultService.allowedAccount(this.locationId)
        if (data.accounts.length) {
          if (isSilent) {
            if (!onlyGroup) this.accounts = []
            this.groups = []
          }
          if (!onlyGroup) this.setAccounts(data.accounts)

          if (data.groups.length) {
            this.setGroups(data.groups)
          }
        }
        this.isFetchingAccounts = false
      } catch (err) {
        this.isFetchingAccounts = false
        console.log(err)
      }
    },
    setAccounts(accounts: any) {
      try {
        const locations = accounts.map((x: any) => {
          const a = moment(x.expire)
          const diff = a.diff(moment(), 'days')
          return {
            id: x.id,
            oauthId: x.oauthId,
            profileId: x.profileId,
            validity: x.expire
              ? moment.duration(diff, 'days').humanize(false, { d: 100 })
              : i18n.global.t('settings.aYear'),
            name: x.name,
            reconnect: x.isExpired,
            isExpired: x.isExpired,
            platform: x.platform,
            type: x.type,
            avatar:
              x.avatar !== 'google'
                ? x.avatar
                : '/assets/img/icon-gmb-location-avatar.svg',
            isAppInstalled:
              x.platform == 'facebook' && x.type == 'group'
                ? x.isAppInstalled
                : true,
            meta: {
              ...x.meta,
              page: x.meta?.page,
              isVerified: x.meta?.isVerified,
              storeCode: x.meta?.storeCode,
              locationId: x.meta?.locationId,
              accountId: x.meta?.accountId,
              storefrontAddress: x.meta?.storefrontAddress,
              urn: x.meta?.urn,
              pageId: x.meta?.pageId,
              protected: x.meta?.protected,
              verified: x.meta?.verified,
              openId: x.meta?.openId,
              username: x.meta?.username,
              userId: x.meta?.userId,
            },
            settingUrl: x.settingUrl,
          }
        })
        this.accounts = locations
      } catch (err) {
        console.log(err)
      }
    },
    setGroups(groups: any) {
      const socialGroups = groups.map((group: any) => {
        const accounts = []
        for (const acc of this.accounts) {
          if (group.accountIds.includes(acc.id)) {
            accounts.push(acc)
          }
        }
        return {
          id: group.id,
          name: group.name,
          accounts: accounts,
        }
      })
      this.groups = socialGroups
    },
    async updateLocationId(id: string) {
      this.setLoader(true)
      this.locationId = id
      await this.getAllowedAccounts()
      this.setLoader(false)
    },
    setLoader(show: boolean) {
      this.appLoading = show
    },
    setPostPayload(key: string, val: any, softUpdate?: boolean) {
      this.payload[key] = val
      this.setErrors(key, false) // disabling errors
      if (this.id && this.payload.scheduleDate && !softUpdate)
        this.setEditSchedulePost(true)
    },
    setPostId(postId: string | undefined) {
      this.id = postId
    },
    resetPostPayload() {
      this.payload = {
        actionType: 'action',
        content: '',
        htmlContent: '',
        applyWatermark: true,
        facebook: {
          content: '',
          htmlContent: '',
          ogTags: undefined,
          mention: [],
        },
        instagram: {
          content: '',
          htmlContent: '',
          ogTags: undefined,
          mention: [],
        },
        google: {
          content: '',
          htmlContent: '',
          ogTags: undefined,
          mention: [],
        },
        linkedin: {
          content: '',
          htmlContent: '',
          ogTags: undefined,
          mention: [],
          postDetails: {
            postAsPdf: false,
            pdfTitle: '',
          },
        },
        twitter: {
          content: '',
          htmlContent: '',
          ogTags: undefined,
          mention: [],
        },
        tiktok: {
          content: '',
          htmlContent: '',
          ogTags: undefined,
          mention: [],
        },
        youtube: {
          content: '',
          htmlContent: '',
          ogTags: undefined,
          postDetails: {
            privacyLevel: YoutubePrivacyLevel.PUBLIC,
            title: '',
            type: YoutubePostType.VIDEO,
          },
        },
        pinterest: {
          content: '',
          htmlContent: '',
          postDetails: {
            link: '',
            title: '',
            boardIds: {},
          },
        },
        community: {
          content: '',
          htmlContent: '',
          postDetails: {
            title: '',
            notifyAllGroupMembers: false,
            postAsUser: {},
          },
        },
        enableComment: false,
        enableDuet: false,
        enableStitch: false,
        videoDisclosure: false,
        promoteYourBrand: false,
        promoteOtherBrand: false,
        locationTags: [],
      }
      this.id = ''
      this.selectedLocations = []
      this.errors = {}
      this.editSchedulePost = false
      this.ogTags = undefined
      this.postType = PostType.POST
    },
    resetErrors() {
      this.errors = {}
    },
    async setPostPayloadData(payload: any, id: string | undefined) {
      try {
        this.payload.content = payload.summary
        this.payload.actionType = ACTION_TYPES[payload.gmbEventType]
        this.selectedLocations = payload.userIds
        this.payload.status = id ? payload.status : ''
        this.id = id
        this.payload.applyWatermark = payload.applyWatermark
        if ((payload && payload.title) || payload.offerTitle)
          this.payload.title = payload.title || payload.offerTitle
        if (payload && payload.startDate) {
          this.payload.startDate = new Date(payload.startDate)
        }
        if (payload && payload.endDate) {
          this.payload.endDate = new Date(payload.endDate)
        }
        if (payload.scheduleDate) {
          this.payload.scheduleDate = moment
            .utc(payload.scheduleDate)
            .local()
            .toDate()
        } else {
          this.payload.recurringDetails = id
            ? payload.recurringDetails
            : undefined
        }
        if (payload.summary) {
          const { changedString } = useHtmlFormat(payload.summary)
          this.payload.htmlContent = changedString
          this.setCustomizedContent('content', payload.summary, undefined)
          this.setCustomizedContent(
            'htmlContent',
            this.payload.htmlContent,
            undefined
          )
        }
        if (payload.terms) this.payload.terms = payload.terms
        if (payload.coupon) this.payload.coupon = payload.terms
        if (payload.actionType) this.payload.buttonLabel = payload.actionType
        if (payload && payload.url) this.payload.buttonLink = payload.url
        if (payload && payload.couponCode)
          this.payload.coupon = payload.couponCode
        if (payload && payload.redeemOnlineUrl)
          this.payload.redeemLink = payload.redeemOnlineUrl
        if (payload && payload.termsConditions)
          this.payload.terms = payload.termsConditions
        if (payload.followUpComment)
          this.payload.followUpComment = payload.followUpComment
        if (payload.media?.length) {
          const mediaPromises = payload.media.map((media: any) => {
            media.id = `id${Math.random().toString(16).slice(2)}`
            if (!media.type || media.type.includes('image')) {
              return useImageHelper(media, false, '')
            } else {
              return useVideoHelper(media)
            }
          })

          this.payload.medias = await Promise.all(mediaPromises)
        }
        if (payload.medias?.length) this.payload.medias = payload.medias
        if (payload.ogTags) this.ogTags = payload.ogTags
        if (payload && payload.approver)
          this.payload.approver = payload.approver
        if (payload && payload.requesterNote)
          this.payload.requesterNote = payload.requesterNote
        if (payload.postType) this.postType = payload.postType
        if (payload.source) this.source = payload.source
        if (payload.categoryId) this.payload.categoryId = payload.categoryId
        if (payload.tags && payload.tags.length > 0)
          this.payload.tags = payload.tags
        if (payload.platform && payload.mentions?.length) {
          this.payload[payload.platform].mention = payload.mentions
        }
        if (payload.locationTags && payload.locationTags.length > 0)
          this.payload.locationTags = payload.locationTags
        this.payload.privacyLevel =
          payload && payload.privacyLevel
            ? payload.privacyLevel
            : this.payload.privacyLevel
        this.payload.enableComment =
          payload && payload.enableComment
            ? payload.enableComment
            : this.payload.enableComment
        this.payload.enableDuet =
          payload && payload.enableDuet
            ? payload.enableDuet
            : this.payload.enableDuet
        this.payload.enableStitch =
          payload && payload.enableStitch
            ? payload.enableStitch
            : this.payload.enableStitch
        this.payload.videoDisclosure =
          payload && payload.videoDisclosure
            ? payload.videoDisclosure
            : this.payload.videoDisclosure
        this.payload.promoteYourBrand =
          payload && payload.promoteYourBrand
            ? payload.promoteYourBrand
            : this.payload.promoteYourBrand
        this.payload.promoteOtherBrand =
          payload && payload.promoteOtherBrand
            ? payload.promoteOtherBrand
            : this.payload.promoteOtherBrand
        this.payload.youtube.postDetails =
          payload && payload.youtubePostDetails
            ? payload.youtubePostDetails
            : this.payload?.youtube?.postDetails
        this.payload.pinterest.postDetails =
          payload && payload.pinterestPostDetails
            ? payload.pinterestPostDetails
            : this.payload?.pinterest?.postDetails
        this.payload.community.postDetails =
          payload && payload.communityPostDetails
            ? payload.communityPostDetails
            : this.payload?.community?.postDetails
        this.payload.linkedin.postDetails =
          payload && payload.linkedinPostDetails
            ? payload.linkedinPostDetails
            : this.payload?.linkedin?.postDetails
        this.errors = {}
      } catch (err) {
        console.error(err)
      }
    },
    setSelectedLocations(locations: string[]) {
      this.selectedLocations = locations
    },
    setErrors(type: keyof Errors, flag: boolean) {
      let key = type
      if (type.includes('.content')) {
        key = type.replace('.content', 'Content')
      }
      this.errors[key] = flag
    },
    setEditSchedulePost(edit: boolean) {
      this.editSchedulePost = edit
    },
    setChangeRouteFlag(flag: boolean) {
      this.changeRoute = flag
    },
    setPreviousRouteName(name: string) {
      try {
        if (window.localStorage) {
          localStorage.setItem('prev-route', name)
        }
      } catch (e) {
        console.log('Local Storage permission issue')
      }
    },
    getPreviousRouteName() {
      try {
        if (window.localStorage) {
          return localStorage.getItem('prev-route') || 'home'
        }
        return 'home'
      } catch (err) {
        return 'home'
      }
    },
    setOgTags(tags: any, platform?: Platform | undefined) {
      if (this.isCustomizedPost && platform) {
        this.payload[platform].ogTags = tags as any
      } else {
        this.ogTags = tags
      }
    },
    setLocationUsers(users: any) {
      this.locationUsers = users.map((user: any) => {
        return {
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          name: user.first_name + ' ' + user.last_name,
          profilePic: user.profile_photo,
          email: user.email,
          role: user.role,
        }
      })
    },
    connectAccount(platform: string, reconnect = false, refresh = false) {
      const data = {
        url: `${config.socialMediaServiceUrl}/oauth/${platform}/start?locationId=${this.locationId}&userId=${this.userId}`,
        target: 'MyWindow',
        feature:
          'toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no',
      }
      if (reconnect) {
        data.url = data.url + '&reconnect=true'
      }
      if (refresh) {
        data.url = data.url + '&refresh=true'
      }
      updateParent('openWindow', data)
    },

    redirectUrl(url: string | undefined) {
      const data = {
        url,
        target: 'MyWindow',
        feature:
          'toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no',
      }
      updateParent('openWindow', data)
    },
    setCustomData(customValues: ICustomValueOptions[]) {
      this.customValues = customValues
    },
    setPostType(type: PostType) {
      this.postType = type
    },
    setLocales(locale: string) {
      this.locale = locale || 'en-US'
      moment.locale(locale || 'en')
      i18n.global.locale.value = locale
      localStorage.setItem('locale', locale)
    },
    setSelectedSocialPlatform(selectedAccounts: any[]) {
      const platform = new Set()
      this.isUnverifiedYoutubeSelected = false
      this.isInstagramSelected = false

      for (const account of selectedAccounts) {
        if (account.platform === Platform.YOUTUBE && !account.meta?.verified) {
          this.isUnverifiedYoutubeSelected = true
        }
        if (account.platform === Platform.INSTAGRAM) {
          this.isInstagramSelected = true
        }
        platform.add(account.platform)
      }

      this.selectedPlatform = Array.from(platform) as Platform[]

      if (this.payload.medias && this.payload.medias.length > 0) {
        this.payload.medias = updateMediaForPlatforms(
          this.payload.medias,
          this.isInstagramSelected
        )
      }
    },
    refreshPlatformMedia() {
      if (this.payload.medias && this.payload.medias.length > 0) {
        this.payload.medias = updateMediaForPlatforms(
          this.payload.medias,
          this.isInstagramSelected
        )
      }
    },
    setCustomizedContent(
      key: 'content' | 'htmlContent',
      val: string,
      platform: Platform | undefined
    ) {
      if (!this.isCustomizedPost) {
        this.setPostPayload(key, val)
        this.payload.facebook[key] = val
        this.payload.google[key] = val
        this.payload.instagram[key] = val
        this.payload.linkedin[key] = val
        this.payload.twitter[key] = val
        this.payload.tiktok[key] = val
        this.payload.youtube[key] = val
        this.payload.pinterest[key] = val
        this.payload.community[key] = val
      } else if (platform) {
        this.payload[platform][key] = val
      }
    },
    setPlatformMentions(
      platform: Platform,
      mentionText: string,
      mention: SearchResult
    ) {
      const data = {
        text: mentionText,
        name: mention.name,
        id: mention.id,
      }
      if (platform === Platform.TWITTER) {
        data.name = mention.id
      }
      this.payload[platform].mention.push(data)
    },
    updateMentions(mentions: PlatformMention[], platform: Platform) {
      this.payload[platform].mention = mentions
    },
    setCustomizedPost(flag: boolean) {
      this.isCustomizedPost = flag
    },
    setCustomizedPlatform(platform: Platform | undefined) {
      this.defaultPlatform = platform
    },
    setWatermarkTemplateId(id: string) {
      this.watermarkTemplateId = id
    },
    setSelectedAccountTypes(types: AccountType[]) {
      this.selectedAccountTypes = types
    },
    setYoutubePostDetails(updatedValues: YoutubePostDetails) {
      this.payload.youtube.postDetails = {
        ...this.payload.youtube.postDetails,
        ...updatedValues,
      }
    },
    setCommunityPostDetails(updatedValues: CommunityPostDetails) {
      this.payload.community.postDetails = {
        ...this.payload.community.postDetails,
        ...updatedValues,
      }
    },
    setTiktokSelectedAccountTypes(types: AccountType[]) {
      this.tiktokSelectedAccountTypes = types
    },
    setPinterestPostDetails(updatedValues: PinterestPostDetails) {
      this.payload.pinterest.postDetails = {
        ...this.payload.pinterest.postDetails,
        ...updatedValues,
      }
    },
    setLinkedinPostDetails(updatedValues: LinkedinPostDetails) {
      this.payload.linkedin.postDetails = {
        ...this.payload.linkedin.postDetails,
        ...updatedValues,
      }
    },
  },
})
