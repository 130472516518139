import { IMedia, MediaErrors } from '@/types/media'
import { i18n } from '../../../../locales'
import { ImageBase } from './base'

export class InstagramReel extends ImageBase {
  /**
   * Max image size supported 8 MB
   */
  readonly maxSize = 8000000

  /**
   * supported image formats
   */
  readonly imageFormat = ['image/jpeg', 'image/jpg']

  /**
   * Max thumbnail image size supported 8 MB
   */
  readonly maxThumbnailSize = 8000000

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
    if (isThumbnail) {
      this.maxThumbnailSizeError()
    }
  }

  protected maxSizeError(): void {
    // Do nothing
  }

  protected typeError(): void {
    // Do nothing
  }

  protected aspectRatioError(): void {
    // do nothing
  }

  protected maxThumbnailSizeError(): void {
    if (this.isThumbnail && this.image.size > this.maxThumbnailSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidVideo.coverSize', {
          limit: '8MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }
}
