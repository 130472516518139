import { IMedia, MediaErrors } from '../../../../types/media'

import { i18n } from '../../../../locales'
import { DisplayPlatform } from '../../../../types'
import { VideoBase } from './base'

export class Pinterest extends VideoBase {
  /**
   * Max video size supported 1 GB
   */
  readonly maxSize = 1000 * 1000 * 1000

  /**
   * Max supported duration in seconds
   */
  readonly maxDuration = 300

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 4

  /**
   * Supported video format
   */
  readonly supportedFormat = ['video/mp4', 'video/m4v']

  /**
   * Supported aspect ratios
   * Full-bleed: 9:16, other options: 1:2, 2:3, 3:4, 4:5, 1:1
   */
  readonly aspectRatios = [
    { width: 9, height: 16 },
    { width: 1, height: 2 },
    { width: 2, height: 3 },
    { width: 3, height: 4 },
    { width: 4, height: 5 },
    { width: 1, height: 1 },
  ]

  /**
   * Max frame rate supported
   */
  readonly maxFrameRate = undefined

  /**
   * Min frame rate supported
   */
  readonly minFrameRate = undefined

  /**
   * Supported Audio Codec
   */
  readonly supportedAudioCodec = undefined

  /**
   * Supported Video Codec
   */
  readonly supportedVideoCodec = ['h264', 'hevc']

  /**
   * Minimum width
   */
  readonly minWidth = undefined

  /**
   * Minimum height
   */
  readonly minHeight = undefined

  /**
   * Supported Audio Channel
   */
  readonly supportedAudioChannel = undefined

  /**
   * Platform name to be display
   */
  readonly platform = DisplayPlatform.PINTEREST

  constructor(media: IMedia) {
    super(media)
    this.typeError()
    this.durationError()
    this.sizeError()
    this.videoCodecError()
    this.aspectRatioError()
  }

  protected typeError(): void {
    if (!this.supportedFormat.includes(this.video.type || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('pinterest.invalidVideo.pinterestVideoType', {
          format: this.getVideoExtension(),
        }),
        invalidType: MediaErrors.VIDEO_TYPE,
      })
    }
  }

  protected durationError(): void {
    if (
      !this.video.duration ||
      this.video.duration > this.maxDuration ||
      this.video.duration < this.minDuration
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('pinterest.invalidVideo.pinterestDuration', {
          platform: this.platform,
          duration: this.video.displayDuration,
        }),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected sizeError(): void {
    if (!this.video.size || this.video.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('pinterest.invalidVideo.pinterestSize', {
          platform: this.platform,
          size: this.video.size ? (this.video.size / 1000000).toFixed(2) : '0',
        }),
        invalidType: MediaErrors.VIDEO_SIZE,
      })
    }
  }

  protected videoCodecError(): void {
    if (!this.supportedVideoCodec.includes(this.video.videoCodecName || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'pinterest.invalidVideo.pinterestVideoCodecName',
          {
            platform: this.platform,
            videoCodecName: this.video.videoCodecName?.toUpperCase(),
          }
        ),
        invalidType: MediaErrors.VIDEO_CODEC,
      })
    }
  }

  protected aspectRatioError(): void {
    const gcd = (a: number, b: number): number => {
      return b === 0 ? a : gcd(b, a % b)
    }

    if (
      !this.video.height ||
      !this.video.width ||
      this.video.height <= 0 ||
      this.video.width <= 0
    ) {
      return
    }

    const actualAspectRatio = this.video.width / this.video.height
    const aspectRatioGCD = gcd(this.video.width, this.video.height)
    const simplifiedWidth = this.video.width / aspectRatioGCD
    const simplifiedHeight = this.video.height / aspectRatioGCD

    const isValidAspectRatio = this.aspectRatios.some(ratio => {
      const ratioGCD = gcd(ratio.width, ratio.height)
      const ratioWidth = ratio.width / ratioGCD
      const ratioHeight = ratio.height / ratioGCD
      return Math.abs(ratioWidth / ratioHeight - actualAspectRatio) < 0.01
    })

    if (!isValidAspectRatio) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('pinterest.invalidVideo.pinterestAspectRatio', {
          aspectRatio: `${simplifiedWidth}:${simplifiedHeight}`,
        }),
        invalidType: MediaErrors.VIDEO_ASPECT_RATIO,
      })
    }
  }

  protected resolutionError(): void {
    // Do nothing
  }

  protected audioCodecNameError(): void {
    // Do nothing
  }

  protected audioChannelError(): void {
    // Do nothing
  }

  protected frameRateError(): void {
    // Do nothing
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    // Do nothing
  }

  protected videoBitrateError(): void {
    // Do nothing
  }
}
