import { requests } from '.'
import config from '../config'

export const OAuthService = {
  fetchFacebookAccounts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/oauth/${locationId}/facebook/accounts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  updateFBAccount: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true)
      .post(`/oauth/${locationId}/facebook/accounts/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchInstagramAccounts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/oauth/${locationId}/instagram/accounts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  updateIGAccount: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true)
      .post(`/oauth/${locationId}/instagram/accounts/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchTwitterAccounts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/oauth/${locationId}/twitter/accounts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  updateTwitterAccount: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true)
      .post(`/oauth/${locationId}/twitter/accounts/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchTiktokAccounts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/oauth/${locationId}/tiktok/accounts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  fetchTiktokBusinessAccounts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/oauth/${locationId}/tiktok-business/accounts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  updateTiktokAccount: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true)
      .post(`/oauth/${locationId}/tiktok/accounts/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchLinkedinAccounts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/oauth/${locationId}/linkedin/accounts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  updateLinkedinAccount: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true)
      .post(`/oauth/${locationId}/linkedin/accounts/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchGmbLocations: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl, true)
      .get(`/oauth/${locationId}/google/locations/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  updateGmbLocation: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl, true)
      .post(`/oauth/${locationId}/google/locations/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchYoutubeAccounts: (locationId: string, id: string) =>
    requests(config.socialMediaServiceUrl)
      .get(`/oauth/${locationId}/youtube/accounts/${id}`)
      .then(response => ({ ...response, data: response.data.results })),
  updateYoutubeAccount: (locationId: string, id: string, body: any) =>
    requests(config.socialMediaServiceUrl)
      .post(`/oauth/${locationId}/youtube/accounts/${id}`, body)
      .then(response => ({ ...response, data: response.data.results })),
  fetchCommunityRedirectUrl: (locationId: string) =>
    requests(config.socialMediaServiceUrl).get(
      `/oauth/community/start?locationId=${locationId}`
    ),
}
