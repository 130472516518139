import { Platform, PlatformMention } from '../types'

import { unicode } from '../util/unicodeCharacter'

interface Format {
  bold: string
  italic: string
}

export const useTextFormat = (
  char: string,
  bold: boolean,
  italic: boolean
): string => {
  let c = ''

  if (bold) {
    const obj = unicode[char] as Format
    if (obj && obj.bold) c = obj.bold
  }
  if (!c && italic) {
    const obj = unicode[char] as Format
    if (obj && obj.italic) c = obj.italic
  }
  return c ? c : char
}

export const useHtmlFormat = (
  inputText: string,
  mentions?: PlatformMention[],
  platform?: string[],
  textDiff?: {
    before: string
    after: string
  }
): { changedString: string; newMentions?: PlatformMention[] } => {
  if (inputText) {
    let replacedText
    let newMentions

    //URLs starting with http://, https://, or ftp://
    const replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
    replacedText = inputText.replace(
      replacePattern1,
      '<a class="text-dodger-blue-500" href="$1" target="_blank">$1</a>'
    )

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a class="text-dodger-blue-500" href="http://$2" target="_blank">$2</a>'
    )

    //Change email addresses to mailto:: links.
    const replacePattern3 =
      /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
    replacedText = replacedText.replace(
      replacePattern3,
      '<a class="text-dodger-blue-500" href="mailto:$1">$1</a>'
    )

    //Change Hashtag to link
    const replacePattern4 = /\B#(\w+)/g
    replacedText = replacedText.replace(
      replacePattern4,
      `<a href="#$1" class="text-dodger-blue-500">#$1</a>`
    )

    //Change @ mention to link
    if (platform?.includes(Platform.TWITTER)) {
      const replacePattern5 = /\B@(\w+)/g
      replacedText = replacedText.replace(
        replacePattern5,
        `<a href="javascript:void(0);" class="text-dodger-blue-500">@$1</a>`
      )
    }

    if (mentions?.length) {
      const data = useMentionReplace(replacedText, mentions, textDiff)
      replacedText = data.replacedText
      newMentions = data.newMentions as PlatformMention[]
    }

    return { changedString: replacedText, newMentions }
  }
  return { changedString: inputText }
}

const useMentionReplace = (
  replacedText: string,
  mentions: PlatformMention[],
  textDiff?: {
    before: string
    after: string
  }
) => {
  let newMentionText = ''
  let afterText = textDiff?.after
  let skipEndIndex
  if (afterText) {
    const regex = /<ins>(.*?)<\/ins>/
    const match = regex.exec(afterText)
    if (match && match[1].length > 2) {
      afterText = match[1]
      const skipStartIndex = replacedText.indexOf(afterText)
      skipEndIndex = skipStartIndex + afterText.length
    }
  }

  const newMentions = []
  let replacedTextCopy = ''
  for (const mention of mentions) {
    let startIndex = replacedText.indexOf(mention.name)
    if (
      skipEndIndex &&
      startIndex < skipEndIndex &&
      afterText.includes(mention.text)
    ) {
      const newIndex = replacedText.indexOf(mention.name, skipEndIndex)
      if (newIndex !== -1) {
        startIndex = newIndex
      }
    }
    if (startIndex !== -1) {
      const newStartIndex = replacedTextCopy.length + startIndex
      replacedTextCopy += replacedText.slice(0, startIndex) + mention.name
      newMentions.push({
        ...mention,
        startIndex: newStartIndex,
        endIndex: newStartIndex + mention.name.length,
      })
      const endIndex = startIndex + mention.name.length
      newMentionText +=
        replacedText.slice(0, startIndex) +
        `<a href="javascript:void(0);" class="text-dodger-blue-500">${mention.name}</a>`
      replacedText = replacedText.slice(endIndex)
    }
  }
  replacedText = newMentionText + replacedText
  return { replacedText, newMentions }
}

export const useLinkExtractor = (text: string): string[] => {
  try {
    let match
    const matched = []
    const re =
      /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.[a-z]))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/g
    if (text) {
      while ((match = re.exec(text))) {
        matched.push(match[0])
      }
    }
    return matched
  } catch (error) {
    return []
  }
}

export const twitterTextLengthCount = (text: string): number => {
  const links = useLinkExtractor(text)
  let linksActualLength = 0
  let linksTwitterLength = 0
  const textLength = text?.length || 0
  if (links.length) {
    for (const link of links) {
      linksActualLength += link?.length || 0
      linksTwitterLength += 23 // default link length for twitter is 23
    }
  }
  const contentLength = textLength - linksActualLength + linksTwitterLength
  return contentLength
}
