interface Config {
  mode: string
  restApiUrl: string
  socialMediaServiceUrl: string
  mediaUrl: string
  templateLocationId: string
  defaultTemplateId: string
  emailServiceUrl: string
  publicApiUrl: string
  contentAIServiceUrl: string
  companyIdForManagingTemplates: string
  customDataServiceUrl: string
  BETA_AGENCIES: string[]
}

const config: { [key: string]: Config } = {
  development: {
    mode: 'dev',
    // restApiUrl: 'http://localhost:5075',
    socialMediaServiceUrl:
      'https://staging.backend.leadconnectorhq.com/social-media-posting',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    mediaUrl: 'https://hl-media-center.web.app',
    templateLocationId: 'hbqTmt4KpPLq1VBciuMW',
    emailServiceUrl: 'https://staging.backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://public-api-dot-highlevel-staging.appspot.com',
    defaultTemplateId: '63a5981cd505aef378b2f347',
    contentAIServiceUrl: 'https://staging.backend.leadconnectorhq.com/content',
    companyIdForManagingTemplates: 'eejDKnO8OMdu5q4F7rMB',
    customDataServiceUrl:
      'https://staging.backend.leadconnectorhq.com/custom-data',
    BETA_AGENCIES: ['5DP4iH6HLkQsiKESj6rh'],
  },
  staging: {
    mode: 'staging',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    socialMediaServiceUrl:
      'https://staging.backend.leadconnectorhq.com/social-media-posting',
    mediaUrl: 'https://hl-media-center.web.app',
    templateLocationId: 'hbqTmt4KpPLq1VBciuMW',
    emailServiceUrl: 'https://staging.backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://public-api-dot-highlevel-staging.appspot.com',
    defaultTemplateId: '63a5981cd505aef378b2f347',
    contentAIServiceUrl: 'https://staging.backend.leadconnectorhq.com/content',
    companyIdForManagingTemplates: 'eejDKnO8OMdu5q4F7rMB',
    customDataServiceUrl:
      'https://staging.backend.leadconnectorhq.com/custom-data',
    BETA_AGENCIES: ['5DP4iH6HLkQsiKESj6rh'],
  },
  beta: {
    mode: 'beta',
    restApiUrl: 'https://backend.leadconnectorhq.com',
    socialMediaServiceUrl:
      'https://backend.leadconnectorhq.com/social-media-posting',
    mediaUrl: 'https://media-center-prod.firebaseapp.com',
    templateLocationId: 'rEiwcbUbKvgMQgJ0e0RI',
    emailServiceUrl: 'https://backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://rest.gohighlevel.com',
    defaultTemplateId: '63bfd5b197647ab3017ef5fc',
    contentAIServiceUrl: 'https://backend.leadconnectorhq.com/content',
    companyIdForManagingTemplates: 'pfpm9iFi4k5yvjnstXWU',
    customDataServiceUrl: 'https://backend.leadconnectorhq.com/custom-data',
    BETA_AGENCIES: ['ktapXmiRvMM4pJHyN3b3', 'YuTUZlUtrwBtvmgByZDW'],
  },
  production: {
    mode: 'production',
    restApiUrl: 'https://backend.leadconnectorhq.com',
    socialMediaServiceUrl:
      'https://backend.leadconnectorhq.com/social-media-posting',
    mediaUrl: 'https://media-center-prod.firebaseapp.com',
    templateLocationId: 'rEiwcbUbKvgMQgJ0e0RI',
    emailServiceUrl: 'https://backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://rest.gohighlevel.com',
    defaultTemplateId: '63bfd5b197647ab3017ef5fc',
    contentAIServiceUrl: 'https://backend.leadconnectorhq.com/content',
    companyIdForManagingTemplates: 'pfpm9iFi4k5yvjnstXWU',
    customDataServiceUrl: 'https://backend.leadconnectorhq.com/custom-data',
    BETA_AGENCIES: ['ktapXmiRvMM4pJHyN3b3', 'YuTUZlUtrwBtvmgByZDW'],
  },
}

const override = false

const overrideConfig = {}
//@ts-ignore
const envConfig = config[process.env.NODE_ENV]

export default override ? { ...envConfig, ...overrideConfig } : envConfig
