import { IMedia, MediaErrors } from '@/types/media'
import { i18n } from '../../../../locales'
import { ImageBase } from './base'

export class Youtube extends ImageBase {
  /**
   * Platform Name
   */
  readonly platform = 'Youtube'
  /**
   * supported image formats
   */
  readonly imageFormat = ['image/jpeg', 'image/jpg', 'image/png']

  /**
   * Max thumbnail image size supported 2 MB
   */
  readonly maxThumbnailSize = 2000000

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
    this.thumbnailTypeError()
    this.maxThumbnailSizeError()
  }

  protected maxSizeError(): void {
    // Do nothing
  }

  protected thumbnailTypeError(): void {
    if (
      this.isThumbnail &&
      this.image.type &&
      !this.imageFormat.includes(this.image.type)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.formatTitle'),
        message: i18n.global.t('createPost.invalidImage.onlyJpegPngFormat', {
          platform: this.platform,
          format: this.getImageExtension(),
        }),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }
  protected aspectRatioError(): void {
    // do nothing
  }

  protected maxThumbnailSizeError(): void {
    if (this.isThumbnail && this.image.size > this.maxThumbnailSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidVideo.coverSize', {
          limit: '2MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }
}
