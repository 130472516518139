export const unicode: { [key: string]: any } = {
  A: {
    bold: '\uD835\uDDD4',
    italic: '\uD835\uDE08',
  },
  B: {
    bold: '\uD835\uDDD5',
    italic: '\uD835\uDE09',
  },
  C: {
    bold: '\uD835\uDDD6',
    italic: '\uD835\uDE0A',
  },
  D: {
    bold: '\uD835\uDDD7',
    italic: '\uD835\uDE0B',
  },
  E: {
    bold: '\uD835\uDDD8',
    italic: '\uD835\uDE0C',
  },
  F: {
    bold: '\uD835\uDDD9',
    italic: '\uD835\uDE0D',
  },
  G: {
    bold: '\uD835\uDDDA',
    italic: '\uD835\uDE0E',
  },
  H: {
    bold: '\uD835\uDDDB',
    italic: '\uD835\uDE0F',
  },
  I: {
    bold: '\uD835\uDDDC',
    italic: '\uD835\uDE10',
  },
  J: {
    bold: '\uD835\uDDDD',
    italic: '\uD835\uDE11',
  },
  K: {
    bold: '\uD835\uDDDE',
    italic: '\uD835\uDE12',
  },
  L: {
    bold: '\uD835\uDDDF',
    italic: '\uD835\uDE13',
  },
  M: {
    bold: '\uD835\uDDE0',
    italic: '\uD835\uDE14',
  },
  N: {
    bold: '\uD835\uDDE1',
    italic: '\uD835\uDE15',
  },
  O: {
    bold: '\uD835\uDDE2',
    italic: '\uD835\uDE16',
  },
  P: {
    bold: '\uD835\uDDE3',
    italic: '\uD835\uDE17',
  },
  Q: {
    bold: '\uD835\uDDE4',
    italic: '\uD835\uDE18',
  },
  R: {
    bold: '\uD835\uDDE5',
    italic: '\uD835\uDE19',
  },
  S: {
    bold: '\uD835\uDDE6',
    italic: '\uD835\uDE1A',
  },
  T: {
    bold: '\uD835\uDDE7',
    italic: '\uD835\uDE1B',
  },
  U: {
    bold: '\uD835\uDDE8',
    italic: '\uD835\uDE1C',
  },
  V: {
    bold: '\uD835\uDDE9',
    italic: '\uD835\uDE1D',
  },
  W: {
    bold: '\uD835\uDDEA',
    italic: '\uD835\uDE1E',
  },
  X: {
    bold: '\uD835\uDDEB',
    italic: '\uD835\uDE1F',
  },
  Y: {
    bold: '\uD835\uDDEC',
    italic: '\uD835\uDE20',
  },
  Z: {
    bold: '\uD835\uDDED',
    italic: '\uD835\uDE21',
  },
  a: {
    bold: '\uD835\uDDEE',
    italic: '\uD835\uDE22',
  },
  b: {
    bold: '\uD835\uDDEF',
    italic: '\uD835\uDE23',
  },
  c: {
    bold: '\uD835\uDDF0',
    italic: '\uD835\uDE24',
  },
  d: {
    bold: '\uD835\uDDF1',
    italic: '\uD835\uDE25',
  },
  e: {
    bold: '\uD835\uDDF2',
    italic: '\uD835\uDE26',
  },
  f: {
    bold: '\uD835\uDDF3',
    italic: '\uD835\uDE27',
  },
  g: {
    bold: '\uD835\uDDF4',
    italic: '\uD835\uDE28',
  },
  h: {
    bold: '\uD835\uDDF5',
    italic: '\uD835\uDE29',
  },
  i: {
    bold: '\uD835\uDDF6',
    italic: '\uD835\uDE2A',
  },
  j: {
    bold: '\uD835\uDDF7',
    italic: '\uD835\uDE2B',
  },
  k: {
    bold: '\uD835\uDDF8',
    italic: '\uD835\uDE2C',
  },
  l: {
    bold: '\uD835\uDDF9',
    italic: '\uD835\uDE2D',
  },
  m: {
    bold: '\uD835\uDDFA',
    italic: '\uD835\uDE2E',
  },
  n: {
    bold: '\uD835\uDDFB',
    italic: '\uD835\uDE2F',
  },
  o: {
    bold: '\uD835\uDDFC',
    italic: '\uD835\uDE30',
  },
  p: {
    bold: '\uD835\uDDFD',
    italic: '\uD835\uDE31',
  },
  q: {
    bold: '\uD835\uDDFE',
    italic: '\uD835\uDE32',
  },
  r: {
    bold: '\uD835\uDDFF',
    italic: '\uD835\uDE33',
  },
  s: {
    bold: '\uD835\uDE00',
    italic: '\uD835\uDE34',
  },
  t: {
    bold: '\uD835\uDE01',
    italic: '\uD835\uDE35',
  },
  u: {
    bold: '\uD835\uDE02',
    italic: '\uD835\uDE36',
  },
  v: {
    bold: '\uD835\uDE03',
    italic: '\uD835\uDE37',
  },
  w: {
    bold: '\uD835\uDE04',
    italic: '\uD835\uDE38',
  },
  x: {
    bold: '\uD835\uDE05',
    italic: '\uD835\uDE39',
  },
  y: {
    bold: '\uD835\uDE06',
    italic: '\uD835\uDE3A',
  },
  z: {
    bold: '\uD835\uDE07',
    italic: '\uD835\uDE3B',
  },
  1: {
    bold: '\uD835\uDFED',
    italic: '',
  },
  2: {
    bold: '\uD835\uDFEE',
    italic: '',
  },
  3: {
    bold: '\uD835\uDFEF',
    italic: '',
  },
  4: {
    bold: '\uD835\uDFF0',
    italic: '',
  },
  5: {
    bold: '\uD835\uDFF1',
    italic: '',
  },
  6: {
    bold: '\uD835\uDFF2',
    italic: '',
  },
  7: {
    bold: '\uD835\uDFF3',
    italic: '',
  },
  8: {
    bold: '\uD835\uDFF4',
    italic: '',
  },
  9: {
    bold: '\uD835\uDFF5',
    italic: '',
  },
  0: {
    bold: '\uD835\uDFF6',
    italic: '',
  },
  '\uD835\uDFED': {
    bold: 1,
    italic: '',
  },
  '\uD835\uDFEE': {
    bold: 2,
    italic: '',
  },
  '\uD835\uDFEF': {
    bold: 3,
    italic: '',
  },
  '\uD835\uDFF0': {
    bold: 4,
    italic: '',
  },
  '\uD835\uDFF1': {
    bold: 5,
    italic: '',
  },
  '\uD835\uDFF2': {
    bold: 6,
    italic: '',
  },
  '\uD835\uDFF3': {
    bold: 7,
    italic: '',
  },
  '\uD835\uDFF4': {
    bold: 8,
    italic: '',
  },
  '\uD835\uDFF5': {
    bold: 9,
    italic: '',
  },
  '\uD835\uDFF6': {
    bold: 0,
    italic: '',
  },
  '\uD835\uDDD4': {
    bold: 'A',
    italic: '\uD835\uDE3C',
  },
  '\uD835\uDDD5': {
    bold: 'B',
    italic: '\uD835\uDE3D',
  },
  '\uD835\uDDD6': {
    bold: 'C',
    italic: '\uD835\uDE3E',
  },
  '\uD835\uDDD7': {
    bold: 'D',
    italic: '\uD835\uDE3F',
  },
  '\uD835\uDDD8': {
    bold: 'E',
    italic: '\uD835\uDE40',
  },
  '\uD835\uDDD9': {
    bold: 'F',
    italic: '\uD835\uDE41',
  },
  '\uD835\uDDDA': {
    bold: 'G',
    italic: '\uD835\uDE42',
  },
  '\uD835\uDDDB': {
    bold: 'H',
    italic: '\uD835\uDE43',
  },
  '\uD835\uDDDC': {
    bold: 'I',
    italic: '\uD835\uDE44',
  },
  '\uD835\uDDDD': {
    bold: 'J',
    italic: '\uD835\uDE45',
  },
  '\uD835\uDDDE': {
    bold: 'K',
    italic: '\uD835\uDE46',
  },
  '\uD835\uDDDF': {
    bold: 'L',
    italic: '\uD835\uDE47',
  },
  '\uD835\uDDE0': {
    bold: 'M',
    italic: '\uD835\uDE48',
  },
  '\uD835\uDDE1': {
    bold: 'N',
    italic: '\uD835\uDE49',
  },
  '\uD835\uDDE2': {
    bold: 'O',
    italic: '\uD835\uDE4A',
  },
  '\uD835\uDDE3': {
    bold: 'P',
    italic: '\uD835\uDE4B',
  },
  '\uD835\uDDE4': {
    bold: 'Q',
    italic: '\uD835\uDE4C',
  },
  '\uD835\uDDE5': {
    bold: 'R',
    italic: '\uD835\uDE4D',
  },
  '\uD835\uDDE6': {
    bold: 'S',
    italic: '\uD835\uDE4E',
  },
  '\uD835\uDDE7': {
    bold: 'T',
    italic: '\uD835\uDE4F',
  },
  '\uD835\uDDE8': {
    bold: 'U',
    italic: '\uD835\uDE50',
  },
  '\uD835\uDDE9': {
    bold: 'V',
    italic: '\uD835\uDE51',
  },
  '\uD835\uDDEA': {
    bold: 'W',
    italic: '\uD835\uDE52',
  },
  '\uD835\uDDEB': {
    bold: 'X',
    italic: '\uD835\uDE53',
  },
  '\uD835\uDDEC': {
    bold: 'Y',
    italic: '\uD835\uDE54',
  },
  '\uD835\uDDED': {
    bold: 'Z',
    italic: '\uD835\uDE55',
  },
  '\uD835\uDDEE': {
    bold: 'a',
    italic: '\uD835\uDE56',
  },
  '\uD835\uDDEF': {
    bold: 'b',
    italic: '\uD835\uDE57',
  },
  '\uD835\uDDF0': {
    bold: 'c',
    italic: '\uD835\uDE58',
  },
  '\uD835\uDDF1': {
    bold: 'd',
    italic: '\uD835\uDE59',
  },
  '\uD835\uDDF2': {
    bold: 'e',
    italic: '\uD835\uDE5A',
  },
  '\uD835\uDDF3': {
    bold: 'f',
    italic: '\uD835\uDE5B',
  },
  '\uD835\uDDF4': {
    bold: 'g',
    italic: '\uD835\uDE5C',
  },
  '\uD835\uDDF5': {
    bold: 'h',
    italic: '\uD835\uDE5D',
  },
  '\uD835\uDDF6': {
    bold: 'i',
    italic: '\uD835\uDE5E',
  },
  '\uD835\uDDF7': {
    bold: 'j',
    italic: '\uD835\uDE5F',
  },
  '\uD835\uDDF8': {
    bold: 'k',
    italic: '\uD835\uDE60',
  },
  '\uD835\uDDF9': {
    bold: 'l',
    italic: '\uD835\uDE61',
  },
  '\uD835\uDDFA': {
    bold: 'm',
    italic: '\uD835\uDE62',
  },
  '\uD835\uDDFB': {
    bold: 'n',
    italic: '\uD835\uDE63',
  },
  '\uD835\uDDFC': {
    bold: 'o',
    italic: '\uD835\uDE64',
  },
  '\uD835\uDDFD': {
    bold: 'p',
    italic: '\uD835\uDE65',
  },
  '\uD835\uDDFE': {
    bold: 'q',
    italic: '\uD835\uDE66',
  },
  '\uD835\uDDFF': {
    bold: 'r',
    italic: '\uD835\uDE67',
  },
  '\uD835\uDE00': {
    bold: 's',
    italic: '\uD835\uDE68',
  },
  '\uD835\uDE01': {
    bold: 't',
    italic: '\uD835\uDE69',
  },
  '\uD835\uDE02': {
    bold: 'u',
    italic: '\uD835\uDE6A',
  },
  '\uD835\uDE03': {
    bold: 'v',
    italic: '\uD835\uDE6B',
  },
  '\uD835\uDE04': {
    bold: 'w',
    italic: '\uD835\uDE6C',
  },
  '\uD835\uDE05': {
    bold: 'x',
    italic: '\uD835\uDE6D',
  },
  '\uD835\uDE06': {
    bold: 'y',
    italic: '\uD835\uDE6E',
  },
  '\uD835\uDE07': {
    bold: 'z',
    italic: '\uD835\uDE6F',
  },
  '\uD835\uDE08': {
    bold: '\uD835\uDE3C',
    italic: 'A',
  },
  '\uD835\uDE09': {
    bold: '\uD835\uDE3D',
    italic: 'B',
  },
  '\uD835\uDE0A': {
    bold: '\uD835\uDE3E',
    italic: 'C',
  },
  '\uD835\uDE0B': {
    bold: '\uD835\uDE3F',
    italic: 'D',
  },
  '\uD835\uDE0C': {
    bold: '\uD835\uDE40',
    italic: 'E',
  },
  '\uD835\uDE0D': {
    bold: '\uD835\uDE41',
    italic: 'F',
  },
  '\uD835\uDE0E': {
    bold: '\uD835\uDE42',
    italic: 'G',
  },
  '\uD835\uDE0F': {
    bold: '\uD835\uDE43',
    italic: 'H',
  },
  '\uD835\uDE10': {
    bold: '\uD835\uDE44',
    italic: 'I',
  },
  '\uD835\uDE11': {
    bold: '\uD835\uDE45',
    italic: 'J',
  },
  '\uD835\uDE12': {
    bold: '\uD835\uDE46',
    italic: 'K',
  },
  '\uD835\uDE13': {
    bold: '\uD835\uDE47',
    italic: 'L',
  },
  '\uD835\uDE14': {
    bold: '\uD835\uDE48',
    italic: 'M',
  },
  '\uD835\uDE15': {
    bold: '\uD835\uDE49',
    italic: 'N',
  },
  '\uD835\uDE16': {
    bold: '\uD835\uDE4A',
    italic: 'O',
  },
  '\uD835\uDE17': {
    bold: '\uD835\uDE4B',
    italic: 'P',
  },
  '\uD835\uDE18': {
    bold: '\uD835\uDE4C',
    italic: 'Q',
  },
  '\uD835\uDE19': {
    bold: '\uD835\uDE4D',
    italic: 'R',
  },
  '\uD835\uDE1A': {
    bold: '\uD835\uDE4E',
    italic: 'S',
  },
  '\uD835\uDE1B': {
    bold: '\uD835\uDE4F',
    italic: 'T',
  },
  '\uD835\uDE1C': {
    bold: '\uD835\uDE50',
    italic: 'U',
  },
  '\uD835\uDE1D': {
    bold: '\uD835\uDE51',
    italic: 'V',
  },
  '\uD835\uDE1E': {
    bold: '\uD835\uDE52',
    italic: 'W',
  },
  '\uD835\uDE1F': {
    bold: '\uD835\uDE53',
    italic: 'X',
  },
  '\uD835\uDE20': {
    bold: '\uD835\uDE54',
    italic: 'Y',
  },
  '\uD835\uDE21': {
    bold: '\uD835\uDE55',
    italic: 'Z',
  },
  '\uD835\uDE22': {
    bold: '\uD835\uDE56',
    italic: 'a',
  },
  '\uD835\uDE23': {
    bold: '\uD835\uDE57',
    italic: 'b',
  },
  '\uD835\uDE24': {
    bold: '\uD835\uDE58',
    italic: 'c',
  },
  '\uD835\uDE25': {
    bold: '\uD835\uDE59',
    italic: 'd',
  },
  '\uD835\uDE26': {
    bold: '\uD835\uDE5A',
    italic: 'e',
  },
  '\uD835\uDE27': {
    bold: '\uD835\uDE5B',
    italic: 'f',
  },
  '\uD835\uDE28': {
    bold: '\uD835\uDE5C',
    italic: 'g',
  },
  '\uD835\uDE29': {
    bold: '\uD835\uDE5D',
    italic: 'h',
  },
  '\uD835\uDE2A': {
    bold: '\uD835\uDE5E',
    italic: 'i',
  },
  '\uD835\uDE2B': {
    bold: '\uD835\uDE5F',
    italic: 'j',
  },
  '\uD835\uDE2C': {
    bold: '\uD835\uDE60',
    italic: 'k',
  },
  '\uD835\uDE2D': {
    bold: '\uD835\uDE61',
    italic: 'l',
  },
  '\uD835\uDE2E': {
    bold: '\uD835\uDE62',
    italic: 'm',
  },
  '\uD835\uDE2F': {
    bold: '\uD835\uDE63',
    italic: 'n',
  },
  '\uD835\uDE30': {
    bold: '\uD835\uDE64',
    italic: 'o',
  },
  '\uD835\uDE31': {
    bold: '\uD835\uDE65',
    italic: 'p',
  },
  '\uD835\uDE32': {
    bold: '\uD835\uDE66',
    italic: 'q',
  },
  '\uD835\uDE33': {
    bold: '\uD835\uDE67',
    italic: 'r',
  },
  '\uD835\uDE34': {
    bold: '\uD835\uDE68',
    italic: 's',
  },
  '\uD835\uDE35': {
    bold: '\uD835\uDE69',
    italic: 't',
  },
  '\uD835\uDE36': {
    bold: '\uD835\uDE6A',
    italic: 'u',
  },
  '\uD835\uDE37': {
    bold: '\uD835\uDE6B',
    italic: 'v',
  },
  '\uD835\uDE38': {
    bold: '\uD835\uDE6C',
    italic: 'w',
  },
  '\uD835\uDE39': {
    bold: '\uD835\uDE6D',
    italic: 'x',
  },
  '\uD835\uDE3A': {
    bold: '\uD835\uDE6E',
    italic: 'y',
  },
  '\uD835\uDE3B': {
    bold: '\uD835\uDE6F',
    italic: 'z',
  },
  '\uD835\uDE3C': {
    bold: '\uD835\uDE08',
    italic: '\uD835\uDDD4',
  },
  '\uD835\uDE3D': {
    bold: '\uD835\uDE09',
    italic: '\uD835\uDDD5',
  },
  '\uD835\uDE3E': {
    bold: '\uD835\uDE0A',
    italic: '\uD835\uDDD6',
  },
  '\uD835\uDE3F': {
    bold: '\uD835\uDE0B',
    italic: '\uD835\uDDD7',
  },
  '\uD835\uDE40': {
    bold: '\uD835\uDE0C',
    italic: '\uD835\uDDD8',
  },
  '\uD835\uDE41': {
    bold: '\uD835\uDE0D',
    italic: '\uD835\uDDD9',
  },
  '\uD835\uDE42': {
    bold: '\uD835\uDDDA',
    italic: '\uD835\uDE0E',
  },
  '\uD835\uDE43': {
    bold: '\uD835\uDE0F',
    italic: '\uD835\uDDDB',
  },
  '\uD835\uDE44': {
    bold: '\uD835\uDE10',
    italic: '\uD835\uDDDC',
  },
  '\uD835\uDE45': {
    bold: '\uD835\uDE11',
    italic: '\uD835\uDDDD',
  },
  '\uD835\uDE46': {
    bold: '\uD835\uDE12',
    italic: '\uD835\uDDDE',
  },
  '\uD835\uDE47': {
    bold: '\uD835\uDE13',
    italic: '\uD835\uDDDF',
  },
  '\uD835\uDE48': {
    bold: '\uD835\uDE14',
    italic: '\uD835\uDDE0',
  },
  '\uD835\uDE49': {
    bold: '\uD835\uDE15',
    italic: '\uD835\uDDE1',
  },
  '\uD835\uDE4A': {
    bold: '\uD835\uDE16',
    italic: '\uD835\uDDE2',
  },
  '\uD835\uDE4B': {
    bold: '\uD835\uDE17',
    italic: '\uD835\uDDE3',
  },
  '\uD835\uDE4C': {
    bold: '\uD835\uDE18',
    italic: '\uD835\uDDE4',
  },
  '\uD835\uDE4D': {
    bold: '\uD835\uDE19',
    italic: '\uD835\uDDE5',
  },
  '\uD835\uDE4E': {
    bold: '\uD835\uDE1A',
    italic: '\uD835\uDDE6',
  },
  '\uD835\uDE4F': {
    bold: '\uD835\uDE1B',
    italic: '\uD835\uDDE7',
  },
  '\uD835\uDE50': {
    bold: '\uD835\uDE1C',
    italic: '\uD835\uDDE8',
  },
  '\uD835\uDE51': {
    bold: '\uD835\uDE1D',
    italic: '\uD835\uDDE9',
  },
  '\uD835\uDE52': {
    bold: '\uD835\uDE1E',
    italic: '\uD835\uDDEA',
  },
  '\uD835\uDE53': {
    bold: '\uD835\uDE1F',
    italic: '\uD835\uDDEB',
  },
  '\uD835\uDE54': {
    bold: '\uD835\uDE20',
    italic: '\uD835\uDDEC',
  },
  '\uD835\uDE55': {
    bold: '\uD835\uDE21',
    italic: '\uD835\uDDED',
  },
  '\uD835\uDE56': {
    bold: '\uD835\uDE22',
    italic: '\uD835\uDDEE',
  },
  '\uD835\uDE57': {
    bold: '\uD835\uDE23',
    italic: '\uD835\uDDEF',
  },
  '\uD835\uDE58': {
    bold: '\uD835\uDDF0',
    italic: '\uD835\uDE24',
  },
  '\uD835\uDE59': {
    bold: '\uD835\uDE25',
    italic: '\uD835\uDDF1',
  },
  '\uD835\uDE5A': {
    bold: '\uD835\uDE26',
    italic: '\uD835\uDDF2',
  },
  '\uD835\uDE5B': {
    bold: '\uD835\uDE27',
    italic: '\uD835\uDDF3',
  },
  '\uD835\uDE5C': {
    bold: '\uD835\uDE28',
    italic: '\uD835\uDDF4',
  },
  '\uD835\uDE5D': {
    bold: '\uD835\uDE29',
    italic: '\uD835\uDDF5',
  },
  '\uD835\uDE5E': {
    bold: '\uD835\uDE2A',
    italic: '\uD835\uDDF6',
  },
  '\uD835\uDE5F': {
    bold: '\uD835\uDE2B',
    italic: '\uD835\uDDF7',
  },
  '\uD835\uDE60': {
    bold: '\uD835\uDE2C',
    italic: '\uD835\uDDF8',
  },
  '\uD835\uDE61': {
    bold: '\uD835\uDE2D',
    italic: '\uD835\uDDF9',
  },
  '\uD835\uDE62': {
    bold: '\uD835\uDE2E',
    italic: '\uD835\uDDFA',
  },
  '\uD835\uDE63': {
    bold: '\uD835\uDE2F',
    italic: '\uD835\uDDFB',
  },
  '\uD835\uDE64': {
    bold: '\uD835\uDE30',
    italic: '\uD835\uDDFC',
  },
  '\uD835\uDE65': {
    bold: '\uD835\uDE31',
    italic: '\uD835\uDDFD',
  },
  '\uD835\uDE66': {
    bold: '\uD835\uDE32',
    italic: '\uD835\uDDFE',
  },
  '\uD835\uDE67': {
    bold: '\uD835\uDE33',
    italic: '\uD835\uDDFF',
  },
  '\uD835\uDE68': {
    bold: '\uD835\uDE34',
    italic: '\uD835\uDE00',
  },
  '\uD835\uDE69': {
    bold: '\uD835\uDE35',
    italic: '\uD835\uDE01',
  },
  '\uD835\uDE6A': {
    bold: '\uD835\uDE36',
    italic: '\uD835\uDE02',
  },
  '\uD835\uDE6B': {
    bold: '\uD835\uDE37',
    italic: '\uD835\uDE03',
  },
  '\uD835\uDE6C': {
    bold: '\uD835\uDE38',
    italic: '\uD835\uDE04',
  },
  '\uD835\uDE6D': {
    bold: '\uD835\uDE39',
    italic: '\uD835\uDE05',
  },
  '\uD835\uDE6E': {
    bold: '\uD835\uDE3A',
    italic: '\uD835\uDE06',
  },
  '\uD835\uDE6F': {
    bold: '\uD835\uDE3B',
    italic: '\uD835\uDE07',
  },
}
