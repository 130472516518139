import { IMedia, MediaErrors } from '@/types/media'

import { i18n } from '../../../../locales'
import { DisplayPlatform } from '../../../../types'
import { ImageBase } from './base'

export class Community extends ImageBase {
  /**
   * Platform Name
   */
  readonly platform = DisplayPlatform.COMMUNITY
  /**
   * supported image formats
   */
  readonly imageFormat = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg',
    'image/svg+xml',
    'image/webp',
    'image/gif',
  ]

  /**
   * supported thumbnail formats
   */
  readonly thumbnailFormat = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg',
  ]

  /**
   * Max image size supported 10 MB
   */
  readonly maxSize = 10 * 1000 * 1000

  /**
   * Max thumbnail image size supported 10 MB
   */
  readonly maxThumbnailSize = 10 * 1000 * 1000

  constructor(media: IMedia, isThumbnail: boolean) {
    super(media, isThumbnail, false)
    if (isThumbnail) {
      this.thumbnailTypeError()
      this.maxThumbnailSizeError()
    } else {
      this.imageTypeError()
      this.maxSizeError()
    }
  }

  protected maxSizeError(): void {
    if (this.image.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidImage.size', {
          limit: '200MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  protected imageTypeError(): void {
    if (this.image.type && !this.imageFormat.includes(this.image.type)) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.formatTitle'),
        message: i18n.global.t('community.invalidImage.notSupportedFormat', {
          platform: this.platform,
          format: this.getImageExtension(),
        }),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }

  protected thumbnailTypeError(): void {
    if (this.image.type && !this.thumbnailFormat.includes(this.image.type)) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.formatTitle'),
        message: i18n.global.t(
          'community.invalidImage.notSupportedThumbnailFormat',
          {
            platform: this.platform,
            format: this.getImageExtension(),
          }
        ),
        invalidType: MediaErrors.IMAGE_TYPE,
      })
    }
  }

  protected maxThumbnailSizeError(): void {
    if (this.image.size > this.maxThumbnailSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidImage.title'),
        message: i18n.global.t('createPost.invalidImage.size', {
          limit: '20MB',
          size: (this.image.size / 1000000).toFixed(2),
        }),
        invalidType: MediaErrors.IMAGE_SIZE,
      })
    }
  }

  protected aspectRatioError(): void {
    // Do nothing
  }
}
