<script setup lang="ts">
import {
  AlertTriangleIcon,
  CheckIcon,
  SearchLgIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIAvatar,
  UIButton,
  UIDivider,
  UIInput,
  UIModal,
  UIModalContent,
  UIModalHeader,
  UISpace,
  UISpin,
  UITextSmNormal,
} from '@gohighlevel/ghl-ui'
import { PropType, computed, h, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { OAuthService } from '../../../service'
import { useSocialMediaStore } from '../../../store'
import { AccountType } from '../../../types'

export interface Profile {
  id: string
  name: string
  username?: string
  avatar: string
  isConnected?: boolean
  type?: string
  error?: boolean
  helpMessage?: string
  helpUrl?: string
  verified?: boolean
}

const props = defineProps({
  showModal: {
    type: Boolean,
    default: true,
  },
  oauthId: {
    type: String,
    default: '',
  },
  reconnectFilter: {
    type: Boolean,
    default: false,
  },
  reconnectAccounts: {
    type: Array as PropType<any[]>,
    default() {
      return []
    },
  },
  accountType: {
    type: String,
    default: AccountType.PROFILE,
  },
})

const { t } = useI18n()
const store = useSocialMediaStore()
const emits = defineEmits(['close', 'refresh'])
const showModal = computed(() => props.showModal)
const profiles = ref<Profile[]>([])
const searchWord = ref('')
const loader = ref(true)
const accountType = computed(() => props.accountType)

const searchProfiles = computed(() => {
  if (!searchWord.value) {
    return profiles.value.filter((x: Profile) => x.type == AccountType.PROFILE)
  } else {
    const inputValue = searchWord.value.toUpperCase()
    return profiles.value.filter(
      (x: Profile) =>
        x.name.toUpperCase().indexOf(inputValue) > -1 &&
        x.type == AccountType.PROFILE
    )
  }
})

const searchBusiness = computed(() => {
  if (!searchWord.value) {
    return profiles.value.filter((x: Profile) => x.type == AccountType.BUSINESS)
  } else {
    const inputValue = searchWord.value.toUpperCase()
    return profiles.value.filter(
      (x: Profile) =>
        x.name.toUpperCase().indexOf(inputValue) > -1 &&
        x.type == AccountType.BUSINESS
    )
  }
})

const placeholder = computed(() => {
  const str = t('connectModal.tikTok.searchPlaceholder')
  return str.length > 48 ? `${str.substring(0, 48)}...` : str
})

watch(showModal, async () => {
  if (showModal.value) {
    searchWord.value = ''
    await fetchAccounts()
  }
})

function closeModal() {
  emits('refresh')
  emits('close')
}

async function fetchTiktokProfileData(oauthId: string) {
  try {
    if (accountType.value == AccountType.BUSINESS) {
      const { data } = await OAuthService.fetchTiktokBusinessAccounts(
        store.locationId,
        oauthId
      )
      if (!data?.profile) return []
      return data.profile
    }
    const { data } = await OAuthService.fetchTiktokAccounts(
      store.locationId,
      oauthId
    )
    if (!data?.profile) return []
    return data.profile
  } catch (error) {
    console.log(error)
  }
}

async function fetchAccounts() {
  try {
    loader.value = true

    if (props.reconnectFilter) {
      filterReconnectAccounts()
    } else if (props.reconnectAccounts.length) {
      reconnectedAccounts()
    } else {
      profiles.value = await fetchTiktokProfileData(props.oauthId)
    }

    loader.value = false
  } catch (error) {
    loader.value = false
    console.log(error)
  }
}

async function addAccount(account: Profile) {
  try {
    loader.value = true

    if (props.reconnectFilter) {
      if (account.type == AccountType.BUSINESS) {
        store.connectAccount('tiktok-business', true)
      } else {
        store.connectAccount('tiktok', true)
      }
      loader.value = false
      closeModal()
    } else {
      const body = {
        originId: account.id,
        name: account.name,
        username: account.username,
        type: account.type,
        avatar: account.avatar,
        verified: account.verified,
      }
      await OAuthService.updateTiktokAccount(
        store.locationId,
        props.oauthId,
        body
      )

      profiles.value = await fetchTiktokProfileData(props.oauthId)

      loader.value = false
    }
  } catch (error) {
    loader.value = false
    console.log(error)
  }
}

async function filterReconnectAccounts() {
  try {
    profiles.value = []
    profiles.value = store.accounts
      .filter(
        account =>
          (account.platform == 'tiktok' ||
            account.platform == 'tiktok-business') &&
          account.isExpired
      )

      .map(ele => {
        return {
          id: ele.id,
          name: ele.name,
          avatar: ele.avatar,
          type: ele.type,
        }
      })
  } catch (error) {
    console.log(error)
  }
}

async function reconnectedAccounts() {
  try {
    profiles.value = []
    store.accounts.forEach(account => {
      const reconnectData = props.reconnectAccounts.find(
        ele => ele.id === account.id
      )
      if (
        (account.platform == 'tiktok' ||
          account.platform == 'tiktok-business') &&
        reconnectData
      ) {
        profiles.value.push({
          id: account.id,
          name: account.name,
          username: account.meta?.username,
          avatar: account.avatar,
          verified: account.meta?.verified,
          type: account.type,
          isConnected: reconnectData.isConnected,
          error: reconnectData.error,
          helpMessage: reconnectData.helpMessage,
          helpUrl: reconnectData.helpUrl,
        })
      }
    })
    return { profiles: profiles.value }
  } catch (error) {
    console.log(error)
  }
}

function icon() {
  return h('img', {
    src: 'https://storage.googleapis.com/msgsndr/DfNXu4rjkbMpKZcEmD8F/media/65643019302496ee3d074b08.svg+xml',
    class: 'w-8 h-8',
  })
}
</script>
<template>
  <UIModal :show="showModal" id="modal" :width="560">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :title="$t('connectModal.tikTok.title')"
        description=""
        :icon="icon"
        @close="closeModal"
      >
      </UIModalHeader>
    </template>
    <UIModalContent>
      <UIInput
        id="search-box"
        type="text"
        v-model:model-value="searchWord"
        :placeholder="placeholder"
      >
        <template #prefix>
          <SearchLgIcon class="w-4 h-5 text-gray-400" />
        </template>
      </UIInput>
      <UIDivider />
      <div
        v-if="loader"
        class="flex flex-col space-y-4 justify-center items-center"
      >
        <UISpin size="large"></UISpin>
      </div>
      <div v-else class="max-h-[450px] overflow-y-scroll pr-3">
        <div v-if="searchProfiles.length">
          <UITextSmNormal>{{
            $t('connectModal.tikTok.profileTitle')
          }}</UITextSmNormal>
          <div
            v-for="profile of searchProfiles"
            :key="profile.id"
            :vertical="true"
            size="large"
            class="pt-4"
          >
            <UISpace
              class="items-center"
              :class="{
                'opacity-50': profile.error,
              }"
              justify="space-between"
            >
              <div class="w-[350px]">
                <UISpace size="large" class="items-center">
                  <UIAvatar :src="profile.avatar" class="h-8 w-8" />
                  <UITextSmNormal class="w-[300px]">{{
                    profile.name
                  }}</UITextSmNormal>
                </UISpace>
              </div>
              <div class="w-[120px] flex justify-end">
                <UISpace
                  v-if="profile.isConnected"
                  class="items-center text-success-600"
                >
                  <CheckIcon class="w-4 h-4" />
                  <UITextSmNormal>{{ $t('common.connected') }}</UITextSmNormal>
                </UISpace>
                <UIButton
                  id="add-page"
                  v-else-if="!props.reconnectAccounts.length"
                  type="primary"
                  @click.prevent="addAccount(profile)"
                  >{{
                    props.reconnectFilter
                      ? $t('common.reconnect')
                      : $t('common.add')
                  }}</UIButton
                >
              </div>
            </UISpace>
            <UISpace size="large" class="items-center" v-if="profile.error">
              <AlertTriangleIcon class="h-8 w-8 text-red-400" />
              <p class="text-xs text-gray-400 w-[420px]">
                {{ profile.helpMessage }}
              </p>
            </UISpace>
          </div>
        </div>
        <div v-if="searchBusiness.length">
          <UIDivider v-if="searchBusiness.length && searchProfiles.length" />

          <UITextSmNormal>{{
            $t('connectModal.tikTokBusiness.profileTitle')
          }}</UITextSmNormal>
          <div
            v-for="profile of searchBusiness"
            :key="profile.id"
            :vertical="true"
            size="large"
            class="pt-4"
          >
            <UISpace
              class="items-center"
              :class="{
                'opacity-50': profile.error,
              }"
              justify="space-between"
            >
              <div class="w-[350px]">
                <UISpace size="large" class="items-center">
                  <UIAvatar :src="profile.avatar" class="h-8 w-8" />
                  <UITextSmNormal class="w-[300px]">{{
                    profile.name
                  }}</UITextSmNormal>
                </UISpace>
              </div>
              <div class="w-[120px] flex justify-end">
                <UISpace
                  v-if="profile.isConnected"
                  class="items-center text-success-600"
                >
                  <CheckIcon class="w-4 h-4" />
                  <UITextSmNormal>{{ $t('common.connected') }}</UITextSmNormal>
                </UISpace>
                <UIButton
                  id="add-page"
                  v-else-if="!props.reconnectAccounts.length"
                  type="primary"
                  @click.prevent="addAccount(profile)"
                  >{{
                    props.reconnectFilter
                      ? $t('common.reconnect')
                      : $t('common.add')
                  }}</UIButton
                >
              </div>
            </UISpace>
            <UISpace size="large" class="items-center" v-if="profile.error">
              <AlertTriangleIcon class="h-8 w-8 text-red-400" />
              <p class="text-xs text-gray-400 w-[420px]">
                {{ profile.helpMessage }}
              </p>
            </UISpace>
          </div>
        </div>
        <div
          v-if="
            (!searchProfiles.length || !searchBusiness.length) && searchWord
          "
          class="w-full my-2 text-red-500 text-sm"
        >
          {{ $t('connectModal.tikTok.noSearchedProfileError') }}
        </div>
      </div>
    </UIModalContent>
  </UIModal>
</template>
