import { i18n } from '../../../../locales'
import { IMedia, MediaErrors } from '../../../../types/media'
import { VideoBase } from './base'

export class TikTokBusiness extends VideoBase {
  /**
   * Max video size supported 1 GB
   */
  readonly maxSize = 1000 * 1000 * 1000

  /**
   * Max supported duration in seconds
   */
  readonly maxDuration = 600

  /**
   * Min supported duration in seconds
   */
  readonly minDuration = 3

  /**
   * Supported video format
   */
  readonly supportedFormat = ['video/mp4', 'video/mov', 'video/webm']

  /**
   * Supported video format
   */
  readonly supportedVideoCodec = ['h264', 'h245', 'vp8', 'vp9', 'hevc']

  /**
   * Max frame rate supported
   */
  readonly maxFrameRate = 60

  /**
   * Min frame rate supported
   */
  readonly minFrameRate = 23

  /**
   * Min frame rate supported
   */
  readonly minHeightWidth = 360

  /**
   * Platform name to be display
   */
  readonly platform = 'TikTok'

  constructor(media: IMedia) {
    super(media)
    this.typeError()
    this.durationError()
    this.sizeError()
    this.resolutionError()
    this.videoCodecError()
    this.frameRateError()
  }

  protected typeError(): void {
    if (!this.supportedFormat.includes(this.video.type || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.tikTokFormat', {
          format: this.getVideoExtension(),
        }),
        invalidType: MediaErrors.VIDEO_TYPE,
      })
    }
  }

  protected durationError(): void {
    if (
      !this.video.duration ||
      this.video.duration > this.maxDuration ||
      this.video.duration < this.minDuration
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.tikTokBusinessDuration',
          {
            duration: this.video.displayDuration,
          }
        ),
        invalidType: MediaErrors.VIDEO_DURATION,
      })
    }
  }

  protected sizeError(): void {
    if (!this.video.size || this.video.size > this.maxSize) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.tikTokSize', {
          size: this.video.size ? (this.video.size / 1000000).toFixed(2) : '0',
        }),
        invalidType: MediaErrors.VIDEO_SIZE,
      })
    }
  }

  protected resolutionError(): void {
    if (
      !(this.video.width && this.video.width >= this.minHeightWidth) ||
      !(this.video.height && this.video.height >= this.minHeightWidth)
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t(
          'createPost.invalidVideo.tikTokBusinessVideoHeightWidth',
          {
            height: this.video.height,
            width: this.video.width,
          }
        ),
        invalidType: MediaErrors.VIDEO_WIDTH_HEIGHT,
      })
    }
  }

  protected audioCodecNameError(): void {
    // Do nothing
  }

  protected audioChannelError(): void {
    // Do nothing
  }

  protected videoCodecError(): void {
    if (!this.supportedVideoCodec.includes(this.video.videoCodecName || '')) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.tikTokVideoCodecName', {
          videoCodecName: this.video.videoCodecName,
        }),
        invalidType: MediaErrors.VIDEO_CODEC,
      })
    }
  }

  protected frameRateError(): void {
    if (
      !this.video.frameRate ||
      this.video.frameRate > this.maxFrameRate ||
      this.video.frameRate < this.minFrameRate
    ) {
      this.isInvalid = true
      this.errors.push({
        title: i18n.global.t('createPost.invalidVideo.notSupportedTitle'),
        message: i18n.global.t('createPost.invalidVideo.tikTokFrameRate', {
          frameRate: this.video.frameRate,
        }),
        invalidType: MediaErrors.VIDEO_FRAME_RATE,
      })
    }
  }

  protected aspectRatioError(): void {
    // Do nothing
  }

  protected audioSampleRateError(): void {
    // Do nothing
  }

  protected audioBitrateError(): void {
    // Do nothing
  }

  protected videoBitrateError(): void {
    // Do nothing
  }
}
