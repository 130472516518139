import { EncryptedPDFError, PDFDocument } from 'pdf-lib'
import { i18n } from '../locales'
import { DocumentErrors, IMedia } from '../types/media'

// Function to fetch file info such as size and MIME type using fetch API
async function fileInfoFromUrl(url: string) {
  try {
    const response = await fetch(url, { method: 'HEAD' })
    if (response.ok) {
      const contentLength = response.headers.get('content-length') || '0'
      const mimeType = response.headers.get('content-type') || ''
      return {
        size: parseInt(contentLength, 10),
        mimeType,
      }
    }
  } catch (error) {
    console.error('Error fetching file info:', error)
  }
  return {
    size: 0,
    mimeType: '',
  }
}

async function fetchPdfData(url: string) {
  try {
    const response = await fetch(url)
    if (!response.ok) throw new Error('Failed to fetch PDF')

    const arrayBuffer = await response.arrayBuffer()
    const pdfDoc = await PDFDocument.load(arrayBuffer, {
      throwOnInvalidObject: false,
    })

    const pages = pdfDoc.getPages()
    if (pages.length === 0) throw new Error('PDF has no pages')
    if (pages.length > 300) throw new Error('PDF has too many pages')

    return {
      pages: pages.length,
    }
  } catch (error) {
    if (error instanceof EncryptedPDFError) {
      throw new Error(i18n.global.t('document.errors.pdfEncrypted'))
    } else if (error instanceof Error) {
      if (
        error.message.includes('encrypted') ||
        error.message.includes('password')
      ) {
        throw new Error(i18n.global.t('document.errors.pdfEncrypted'))
      } else if (
        error.message.includes('corrupt') ||
        error.message.includes('invalid')
      ) {
        throw new Error(i18n.global.t('document.errors.pdfCorrupted'))
      } else if (error.message === 'PDF has too many pages') {
        throw new Error(i18n.global.t('document.errors.pdfTooManyPages'))
      }
    }
    throw new Error(i18n.global.t('document.errors.pdfGenericError'))
  }
}

// Validate and extract document details
export const useDocumentHelper = async (
  media: any,
  requestId?: string
): Promise<IMedia> => {
  try {
    const document: IMedia = {
      url: media.url,
      type: media.type,
      size: 0,
      invalid: false,
      requestId,
      name: media.name,
      errorMessages: [],
    }

    // Fetch file info
    const fileInfo = await fileInfoFromUrl(media.url)
    document.size = fileInfo.size || 0
    document.type = fileInfo.mimeType || media.type || ''

    const maxSize = 100 * 1024 * 1024 // 100 MB

    // Validate document type
    if (document.type !== 'application/pdf') {
      document.invalid = true
      document.errorMessages?.push({
        title: i18n.global.t('document.errors.invalidTypeTitle'),
        message: i18n.global.t('document.errors.invalidTypeMessage'),
        invalidType: DocumentErrors.DOC_TYPE,
      })
    } else {
      try {
        const pdfData = await fetchPdfData(media.url)
        if (pdfData) {
          document.pages = pdfData.pages
        }
      } catch (error) {
        document.invalid = true
        document.errorMessages?.push({
          title: i18n.global.t('document.errors.invalidPdfTitle'),
          message:
            error instanceof Error
              ? error.message
              : i18n.global.t('document.errors.pdfGenericError'),
          invalidType: DocumentErrors.DOC_ACCESS,
        })
      }
    }

    // Validate document size
    if (document.size > maxSize) {
      document.invalid = true
      document.errorMessages?.push({
        title: i18n.global.t('document.errors.sizeExceededTitle'),
        message: i18n.global.t('document.errors.sizeExceededMessage', {
          limit: '100MB',
          size: (document.size / (1024 * 1024)).toFixed(2),
        }),
        invalidType: DocumentErrors.DOC_SIZE,
      })
    }

    return document
  } catch (err) {
    console.error('Error processing document:', err)
    return {
      url: media.url,
      type: '',
      size: 0,
      invalid: true,
      name: '',
      errorMessages: [],
    }
  }
}

export function updateMediaForPlatforms(
  medias: IMedia[],
  isInstagramSelected: boolean
): IMedia[] {
  return medias.map(media => {
    if (isInstagramSelected && media.instagramMedia) {
      return {
        ...media.instagramMedia,
        primaryMedia: media,
        isInstaMedia: true,
      }
    } else if (
      !isInstagramSelected &&
      media.isInstaMedia &&
      media.primaryMedia
    ) {
      return { ...media.primaryMedia, instagramMedia: media }
    }
    return media
  })
}
