import { useSocialMediaStore } from "../store"

export function updateParent (name: string, value: any) {
  try {
    const store = useSocialMediaStore()
    if (store.appHandshake) {
      store.appHandshake.then((parent: any) => {
        parent.emit(name, value)
      })
    }
  } catch (error) {}
}
